.boxCard-function {
    @extend .boxCard-base;

}

.fileCard {
    @extend .fileCard-base;
}

.title {
    .tag {
        color: $color-red;
    }
}

.rlHome {
    @extend .baseContent;

    .quickStart {

        .boxCard-function {
            width: 280px;
            height: 260px;
        }

    }
}

.psHome {
    @extend .baseContent;

}

.AIProgress {
    @extend .baseContent;
    //padding-bottom: 100px;
    cursor: default;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .stepProgress {}

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;

        >* {
            @extend .baseUnit;
        }

        .psCV {
            .uploadCV {
                @extend .baseItem;

                .title {}
            }

            .boxGroup {
                display: flex;
                align-items: center;
                gap: 28px;
                flex-wrap: wrap;

                .box {
                    .boxCard-function {
                        width: 68vw;
                        max-width: 380px;
                        height: 320px;
                    }
                }

                .textDivider {
                    @extend .font6;
                    color: $color-gray-mid;
                }

            }

            .uploadProgress {
                .bgBar {
                    @extend .bgBar-blue;
                    display: flex;
                    gap: 32px;

                    .icon {
                        width: 48px;
                        height: 48px;
                        display: flex;
                        align-items: center;
                        color: $color-blue-mid;
                        font-size: 48px;
                        border: 2px solid $color-blue-mid;
                        border-radius: 8px;
                    }

                    .content {
                        all: unset;
                        display: flex;
                        flex-direction: column;
                        gap: 4px;
                        width: 80%;

                        .progressBar {}
                    }
                }
            }

            .QAGroup {}
        }

        .psStoryline {
            .storyGroup {
                display: flex;
                gap: 32px;

                .storyUnit {
                    display: flex;
                    flex-direction: column;
                    gap: 36px;
                    width: 520px;
                    position: relative;
                    color: $color-blue-mid;
                    border: 2px solid transparent;

                    transition: .3s;

                    &:hover {
                        @extend .choosed;

                    }

                    .title {
                        @extend .font3;
                        color: $color-blue-dark;
                        text-align: center
                    }

                    .content {
                        @extend .font4;
                        color: $color-blue-dark;

                        .textUnit {
                            >li {
                                margin: unset;
                            }
                        }

                    }

                    .chooseTag {
                        display: none;
                    }

                    .chooseTag {
                        position: absolute;
                        font-size: 48px;
                        top: -40px;
                        left: 0;
                        width: 100%;
                        text-align: center;

                        z-index: 10;

                        &::after {
                            content: "";
                            width: 36px;
                            height: 36px;
                            border-radius: 50%;
                            background-color: white;
                            position: absolute;
                            top: 18px;
                            left: 46%;
                            z-index: -1;
                        }


                    }

                    &.choosed {
                        border: 2px solid $color-blue-mid;
                        color: $color-blue-mid;


                        .title {
                            color: $color-blue-mid;

                        }

                        .content {
                            color: $color-blue-mid;

                        }

                        .chooseTag {
                            display: inline-block;
                        }
                    }
                }
            }
        }

        .psPersonal {
            >* {
                @extend .baseItem;

            }


        }

        .psOverview {
            overflow: scroll;
            //max-height: calc(100vh - 360px);
            scrollbar-width: none;
        }

    }

    .operateBar {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 48px 0 0 0;
        justify-content: space-between;

        .stepLeftBtn {
            @extend .button-stepLeft;
            width: 200px;
            height: 48px;
        }

        .stepRightBtn {
            @extend .button-stepRight;
            width: 200px;
            height: 48px;

        }

        .ban {
            cursor: default;
            background-color: $color-gray-mid;
        }
    }

    .fullFillPage {
        width: 100vw;
        height: 100vh;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        position: fixed;
        display: flex;
        background-color: rgba(29, 29, 29, .3);
        z-index: 10;

        justify-content: center;
        align-items: center;
    }

    .ExOperateBar {
        display: flex;
        flex-direction: row;

        .add {
            cursor: pointer;
            color: $color-blue-mid;
            font-weight: 600;
            padding: 16px 0;
            width: 200px;

            >span {
                margin-left: 10px;
            }

            &:hover {
                font-weight: 700;
            }
        }

        .delete {
            cursor: pointer;
            color: rgb(220, 60, 60);
            font-weight: 600;
            padding: 16px 0;
            width: 200px;

            >span {
                margin-left: 10px;
            }

            &:hover {
                font-weight: 700;
            }
        }
    }
}