@use "sass:map";

.layout-home {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 200px;
    padding-bottom: 200px;
    position: relative;


    @media screen and (max-width:$breakpoint-mid) {
        & {
            gap: 48px;
        }
    }

    @media screen and (max-width:$breakpoint-small) {
        & {
            gap: 64px;
        }
    }
}

// .headnav {
//     height: 80px;
//     width: 100%;
//     position: sticky;
//     top: -1px;
//     z-index: 1000;

//     &-container {
//         width: 100%;
//         max-width: $size-max;
//         padding: 0px 20px;
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//         transition: .5s ease;
//         gap: 20px;
//     }

//     &-items {
//         display: flex;
//         flex-direction: row;
//         justify-content: start;
//         gap: 70px;
//         padding-left: 20px;
//         margin-right: 10%;

//     }

//     &-item {
//         @extend .font4;
//         @extend .hoverUp;
//         cursor: pointer;
//         width: fit-content;
//     }

//     &-sticking {
//         width: 95%;
//     }

//     //sticking时样式
//     &-sticking &-container {
//         margin-top: 10px;
//         box-shadow: 0 3px 20px 2px rgba(183, 183, 183, 0.618);
//         background-color: $color-white;
//         border-radius: 60px;
//         padding: 0 60px;
//     }

//     @media screen and (max-width:$breakpoint-mid) {
//         &-item {
//             display: none;
//         }
//     }

//     .applicationNode {
//         display: flex;
//         align-items: center;
//         gap: 20px;

//         .languageNode {
//             font-size: 36px;
//             cursor: pointer;
//         }
//     }
// }


.headnav {
    height: 80px;
    width: 100%;
    position: sticky;
    top: -1px;
    z-index: 1000;

    &-container {
        width: 100%;
        max-width: $size-max;
        padding: 0px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: .5s ease;
        gap: 20px;

        &-icon {
            &:hover {
                cursor: pointer;
            }
        }
    }

    &-items {
        display: flex;
        flex-direction: row;
        justify-content: start;
        gap: 50px;
        padding-left: 20px;
        margin-right: 10%;
    }

    &-item {
        @extend .font4;
        @extend .hoverUp;
        cursor: pointer;
        width: fit-content;
    }

    &-sticking {
        width: 99%;
    }

    //sticking时样式
    &-sticking &-container {
        margin-top: 10px;
        box-shadow: 0 3px 20px 2px rgba(183, 183, 183, 0.618);
        background-color: $color-white;
        border-radius: 12px;
        padding: 0 60px;
    }


    @media screen and (max-width:$breakpoint-mid) {

        &-items {
            display: none;
            display: flex;
            flex-direction: row;
            gap: 15px;
        }

        .applicationNode {
            gap: 0;
            transform: translateX(20px);
        }
    }

    .applicationNode {
        display: flex;
        align-items: center;
        gap: 20px;
        padding-right: 10px;

        .languageNode {
            font-size: 36px;
            cursor: pointer;
        }

        .dashboradButton {
            display: flex;
            padding: 16px 48px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 12px;
            border: 1px solid #287EFF;
            background: #F0F6FF;
            color: #287EFF;
            text-align: center;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

        }
    }

    .menu-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding: 8px;
        border-radius: 4px;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #f0f0f0;
        }
    }
}




.dropdown-menu {
    position: absolute;
    top: 100%;
    right: 20px;
    background-color: white;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    width: 200px;
    z-index: 2000;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
}

.dropdown-item {
    padding: 12px 16px;
    font-size: 16px;
    color: #333;
    cursor: pointer;
    transition: background-color 0.2s ease;
    white-space: nowrap;
}

.dropdown-item:hover {
    background-color: #f0f0f0;
}

.dropdown-item+.dropdown-item {
    border-top: 1px solid #ddd;
}

.titleFirst {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 45px;
    z-index: 1;
    position: relative;


    .join {
        display: flex;
        width: fit;
        height: 51px;
        padding: 6px 7px;
        align-items: center;
        gap: 15px;
        border-radius: 27px;
        border: 1px solid #FFB863;
        background: #F9F0E6;
        margin-top: -90px;

        @media screen and (max-width:$breakpoint-mid) {
            margin-top: 0px;
        }



        span {
            color: #000;
            text-align: center;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            /* 150% */
        }

    }

    .title {
        @extend .font1;
        text-align: center;
        z-index: 10;
        word-break: normal;

        .highlight {
            color: $color-blue-mid;
        }

        .titletext {
            .letter {
                display: inline-block;
            }
        }

        // &::first-letter {
        //     color: $color-blue-mid;
        // }

        @media screen and (max-width:$breakpoint-small) {
            font-size: 48px;
            line-height: 1.2;
            padding: 0 20px;
            word-break:keep-all;
        }
    }

    .text {
        z-index: 10;
        @extend .font3;
        text-align: center;
        max-width: min(80vw, 1000px);
    }

    .btn {
        @extend .button-normal-fullfill;
        max-width: 80vw;
        display: flex;
        padding: 16px 48px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 12px;
        background: #287EFF;
        color: #FFF;
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        /* 24px */
    }

    .background {
        @extend .d-all-center;



        >.layers {
            @extend .d-all-center;

            @for $i from 1 through 9 {
                >.layer-#{$i} {
                    width: 120%;

                    @media screen and (max-width:$breakpoint-mid) {
                        width: 100%;
                    }

                    height: 2px;
                    border-radius: 50%;

                    background-color: rgba(217, 217, 217, 0.8);
                    opacity: 1;
                    position: absolute;
                    top: ($i - 2) * 22% - 7%;
                    z-index: -1;
                    mask-size:100%;
                    mask-image: linear-gradient(to right, transparent 1%, white 25%, white 75%, transparent 99%);


                }
            }

        }

        >.dots {
            @extend .d-all-center;

            @for $i from 1 through 12 {
                >.dot-#{$i} {
                    $size: unquote('#{random(32)}px');
                    width: $size;
                    height: $size;
                    border-radius: 50%;
                    background-color: rgba(217, 217, 217, 0.8);
                    opacity: 1;
                    position: absolute;
                    top: unquote('#{random(150) - 60}%');
                    left: unquote('#{random(80)}%');
                    // top: 0;
                    // left: 0;
                    z-index: -1;


                }
            }
        }

        >.rects {
            z-index: 1;
            @extend .d-all-center;
            @extend .R-hidden-mid;
            $positions: (
                "1":("x":-400px, "y":0px, "to":("x":-300px, "y":680px)),
                "2":("x":400px, "y":0px, "to":("x":300px, "y":680px)),
                "3":("x":-300px, "y":360px, "to":("x":-300px, "y":800px)),
                "4":("x":300px, "y":360px, "to":("x":300px, "y":800px)),
                "5":("x":-500px, "y":520px, "to":("x":-300px, "y":800px)),
                "6":("x":500px, "y":520px, "to":("x":300px, "y":800px)),
            );

        @for $i from 1 through 6 {
            >.rect-#{$i} {
                $size: 82px;
                width: $size;
                height: $size;
                border-radius: 18px;
                background-color: rgba(228, 233, 242, 1);
                position: absolute;
                box-shadow: 10px 14px 24px 1px rgba(0, 0, 0, 0.15), -2px -2px 3px 0px rgba(0, 0, 0, 0.1) inset;
                top: -20%;
                z-index: -1;
                transform: translateX(map.get($positions, #{$i}, "x")) translateY(map.get($positions, #{$i}, "y")) rotateZ(15deg * ((($i % 2) * 2) - 1));
                display: flex;
                justify-content: center;
                align-items: center;
                // animation: scrollToMerge-#{$i} ease forwards;
                // animation-timeline: scroll();
                // animation-range: 0vh 40vh;



                @keyframes scrollToMerge-#{$i} {
                    100% {
                        transform: translateX(map.get($positions, #{$i}, "to", "x")) translateY(map.get($positions, #{$i}, "to", "y")) rotateZ(9deg * ((($i % 2) * 2) - 1));
                    }
                }

                img {
                    width: 70%;
                    height: 70%;
                    object-fit: cover;
                }
            }
        }



    }

    >.particles {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        top: 128x;
        z-index: -1;

    }


}


}

.imageFirst {
    z-index: 20;
    position: relative;
    @extend .d-all-center;
    top: -10px;

    .imgContainer {
        .img {
            overflow: hidden;
        }

        $minWidth: 160px;
        $maxWidth: 2000px;
        $width: 100%;
        $radio: 0.62;
        position: relative;
        //padding: 20px 16px;
        //box-shadow: $box-shadow;
        background-color: $color-bg;
        width: $width;
        height: $radio*$width;
        min-width: $minWidth;
        max-width: $maxWidth;
        min-height: $minWidth*$radio;
    }

    // max-height: $maxWidth*$radio;
    .background {
        position: absolute;
        z-index: -10;
        width: 100%;
        height: 100%;
        @extend .d-all-center;

        @mixin bg {
            width: min(50vw, $size-mid);
            height: min(50vw, $size-mid);
            position: absolute;
            border-radius: 50%;
            background-color: rgba(250, 243, 238, 1);
            @extend .d-all-center;


            &::after {
                content: "";
                position: absolute;

                width: 90%;
                height: 90%;
                border: 3px solid rgba(224, 224, 225, 1);
                border-radius: 50%;
                z-index: 10;
            }
        }

        .bg1 {
            @include bg;
            top: 0%;
            left: 46%;

            @media screen and (max-width:$breakpoint-mid) {
                left: 45%;
            }
        }

        .bg2 {
            @include bg;
            top: 8%;
            left: 0%;
        }

    }
}

.dataSection {
    display: flex;
    align-items: center;
    justify-content: center;
    //gap: calc(2vw + 10px);
    gap: 80px;
    width: 100%;
    background-color: #F0F6FF;
    padding: 70px 66px 70px 66px;
    color: $color-blue-mid;
    z-index: 10;
    // margin-top: 150px;
    margin-bottom: 0;


    .divider {

        background-color: gray;
        width: 3px;
        margin: 5px 0;
    }

    &-card {
        @extend .d-y-center;

        .number {
            @extend .font2;
            color: #287EFF;
            text-align: center;

            /* H1 */
            font-family: Poppins;
            font-size: 50px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            /* 86.4px */
        }

        .tip {
            @extend .font4;
            color: #000;
            text-align: center;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 27px;
            /* 135% */
        }
    }

    @media screen and (max-width:$breakpoint-mid) {
        padding: 30px 21px;

        &-card {
            .number {
                font-size: 24px;
            }

            .tip {
                width: 100%;
                font-size: 14px;
                letter-spacing: 0;
                max-width: 90px;
            }
        }
    }
}


.dataSection2 {
    display: flex;
    width: 400px;
    height: 435px;
    padding: 36px 30px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    .item {
        display: flex;
        align-items: flex-start;
        gap: 24px;
        align-self: stretch;
        width: 100%;

        .icon-container {
            display: flex;
            width: 62px;
            height: 62px;
            padding: 10px;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            border-radius: 8px;
            background: #FFF;
            box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.25);

            .icon {
                width: 41.205px;
                height: 39.282px;
                flex-shrink: 0;

            }
        }

        .numberContainer{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;

            .number{
                color: #000;
font-family: Poppins;
font-size: 34px;
font-style: normal;
font-weight: 600;
line-height: 120%; /* 40.8px */
            }
            .word{
                color: #000;
text-align: center;
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 27px; /* 225% */
            }

        }
    }
}


.functionCard {
    height: calc(700px + 6vw);
    width: 100vw;
    // max-width: calc($size-max - 1vw);
    // @extend .d-all-center;
    user-select: none;


    @media screen and (max-width:$breakpoint-mid) {
        margin-top: 150px;
        margin-bottom: 200px;
        height: calc(850px + 6vw);
        width: 90vw;

        .container .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            height: 560px;
            width: 100%;

            .img {
                order: 1;
                text-align: center;
                display: flex;
                justify-self: center;

                img {
                    object-fit: contain;
                }
            }

            .text {
                order: 2;
                width: 90%;
                padding: 0;
                text-align: center;
                gap: 10px;


                .title {
                    font-size: 18px;
                    text-align: center;
                    word-break: normal;
                }
            }

            @mixin funcCard-index ($index) {
                .funcCard-index-#{$index} {
                    height: auto;
                }
            }
        }

        @mixin funcCard-index ($index) {
            .funcCard-index-#{$index} {
                transform: translateY(10%*($index - 1));
            }

        }
    }

    @media screen and (max-width:$breakpoint-small) {
        margin-top: 0;
        margin-bottom: -100px;
        height: calc(600px + 6vw);
        width: 100vw;

        .container {
            margin-right: 0;
            margin: 0 auto;
            width: 100%;
        }

        @mixin funcCard-index ($index) {
            scale: 0.9;

            .funcCard-index-#{$index} {
                transform: translateY(15px);
            }
        }
    }

    @media screen and (max-width:900px) {
        margin: 10px auto;
        margin-bottom: 50px;
    }

    @media screen and (max-width:610px) {
        margin-bottom: 100px;
    }

    .container {
        position: relative;
        display: flex;
        justify-content: center;
        width: 100vw;
        height: 100%;
        transform: translateY(-15%);
        z-index: 10;

        .content,
        .text {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .img {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }

            &:nth-child(2) {
                width: 100%;
                margin-bottom: 20px;
            }

            &:last-child {
                img {
                    object-fit: contain;
                    width: 95%;
                }
            }
        }

        @media screen and (max-width:$breakpoint-small) {
            transform: translateY(10%);

            .tip {
                padding: 10px 24px;
                border-radius: 24px;
                font-size: 20px;
            }
        }



        @mixin funcCard-index ($index) {
            .funcCard-index-#{$index} {
                position: absolute;
                top: 0;
                z-index: 10-$index;

                transform: translateY(5%*($index - 1));
                filter: blur(0px + ($index - 1)*1px);

                @if $index !=1 {
                    display: none;
                    scale: 100%-10%*($index - 1);
                    height: 90%;

                    &::after {
                        position: absolute;
                        top: 0;
                        left: 0;
                        content: "";
                        width: 100%;
                        height: 100%;
                        background-color: $color-blue-light;
                        background-image: cover url("../../static/Image/FuncCardBack_1.png");
                        border-radius: 36px;
                    }
                }

                @media screen and (max-width:$breakpoint-small) {
                    flex-direction: column;
                    gap: 20px;
                }
            }
        }

        @for $i from 1 through 4 {
            @include funcCard-index($i) // & .caseCard:nth-child(#{$i}) {
        }

    }

    .funcCard {
        @extend .d-y;
        // @extend .hoverBig;
        align-items: center;
        gap: $gap;
        box-shadow: $box-shadow;
        padding-top: $padding-2;
        padding-bottom: 32px;
        border-radius: 60px;
        background-color: white;
        transition: .3s ease;
        cursor: pointer;
    }

    .tip {
        text-align: center;
        @extend .font3;
        border: 2px solid $color-blue-mid;
        padding: 16px 48px;
        border-radius: 48px;
        background-color: $color-blue-light;
    }


    .content {
        @extend .d-x;
        gap: 2vw;

        @media screen and (max-width: $breakpoint-small) {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            height: auto;

            .text {
                width: 90%;
                padding-left: 0;
                padding-bottom: 20px;
                text-align: center;
                height: auto;
                overflow: auto;
                max-height: 200px;
            }

            .img {
                width: 100%;
                height: auto;

                img {
                    width: 100%;
                    height: auto;
                    object-fit: contain;
                }
            }
        }
    }

    .text {
        @extend .d-y;
        width: 30%;
        padding-left: 32px;
        padding-bottom: $padding;
        gap: 28px;

        .content-text {
            @extend .d-y;
            gap: 16px;
        }

        .tip-title {
            @extend .font3;
        }
    }

    .title {
        @extend .font2;
        word-break: normal;
        line-height: 1.3;
    }

    .p {
        @extend .font5;
        @extend .R-hidden;
    }

    .img {
        width: 100%;

    }

    .background {
        width: 96vw;
        height: calc(520px + 12vw);

        @media screen and (max-width:$breakpoint-mid) {
            height: 200px;
        }

        @media screen and (max-width:$breakpoint-small) {
            height: 500px;
        }

        position: absolute;
    }

    .line-arrow {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 6px;
    }

    .funcCard-btn-container {
        // display: flex;
        // justify-content: center;
        --color-text: $color-blue-mid;
        --color-outline: #ff145b80;
        --color-shadow: #00000080;
        --color-background: $color-blue-mid;
    }

    .funcCard-btn-content {
        display: flex;
        align-items: center;
        padding: 5px 10px;
        text-decoration: none;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-size: 30px;
        // background: $color-blue-light;
        color: $color-blue-mid;
        transition: 1s;
        border-radius: 100px;
        // box-shadow: 0 0 0.2em 0 $color-blue-mid;
        transform: rotateZ(90deg);
        margin-top: -20px;
    }

    .funcCard-btn-content:hover,
    .funcCard-btn-content:focus {
        transition: 0.5s;
        outline: 0.1em solid transparent;
        outline-offset: 0.2em;
        // box-shadow: 0 0 0.4em 0 $color-blue-mid;
    }

    .funcCard-btn-content .funcCard-icon-arrow {
        transition: 0.5s;
        margin-right: 0px;
        transform: scale(0.6);
    }

    .funcCard-btn-content:hover .funcCard-icon-arrow {
        transition: 0.5s;
        margin-right: 25px;
    }

    .funcCard-icon-arrow {
        width: 20px;
        margin-left: 15px;
        position: relative;
        top: 6%;
        fill: $color-blue-mid;
        color: $color-blue-mid;
    }

    #funcCard-arrow-icon-one {
        transition: 0.4s;
        transform: translateX(-60%);
    }

    #funcCard-arrow-icon-two {
        transition: 0.5s;
        transform: translateX(-30%);
    }

    #funcCard-arrow-icon-three {
        fill: $color-blue-mid;
    }

    .funcCard-btn-content:hover #funcCard-arrow-icon-three {
        animation: funcCard-color-anim 1s infinite 0.2s;
    }

    .funcCard-btn-content:hover #funcCard-arrow-icon-one {
        transform: translateX(0%);
        animation: funcCard-color-anim 1s infinite 0.6s;
    }

    .funcCard-btn-content:hover #funcCard-arrow-icon-two {
        transform: translateX(0%);
        animation: funcCard-color-anim 1s infinite 0.4s;
    }

    @keyframes funcCard-color-anim {
        0% {
            fill: white;
        }

        50% {
            fill: $color-blue-mid;
        }

        100% {
            fill: white;
        }
    }
}

.cases {
    gap: calc(28px + 5vw);
    min-height: 480px;


    .title {
        @extend .font2;
        word-break: normal;
    }

    .cardGroup {
        flex-direction: row;
        flex-wrap: nowrap;
        width: 80vw;
        gap: 0px;

        @mixin card-index($index) {
            .card-index-#{$index} {
                transform: translate(-($index - 2) * 160px, abs($index - 2) * 30px) rotate(($index - 2)*5deg);
                z-index: 10 - $index;

                @media screen and (min-width:$breakpoint-mid) {
                    &:hover {
                        .hoverItem {
                            transform: translateX(-($index - 2) * 80px) translateY(-48px) rotate(-($index - 2)*5deg);
                        }
                    }
                }


                @media screen and (max-width:$breakpoint-mid) {
                    & {
                        transform: unset;
                    }
                }
            }
        }

        @include card-index(1);
        @include card-index(2);
        @include card-index(3);

        .card {
            &-stacked {
                width: 100%;
                max-width: 480px;
                min-width: 360px;
                transition: .3s ease;
                cursor: pointer;

                .hoverItem {
                    width: 100%;
                    background-color: white;
                    border: unset;
                    box-shadow: $box-shadow;
                    border-radius: 20px;
                    padding: calc(1% + 10px) calc(1% + 16px);
                    transition: .3s ease;
                }

                @media screen and (min-width:$breakpoint-mid) {
                    &:hover {
                        z-index: 200;


                        .hoverItem {
                            scale: 1.6;
                            box-shadow: 0px 12px 20px 8px rgba(108, 108, 108, 0.3);

                        }
                    }
                }
            }
        }

        @media screen and (max-width:$breakpoint-mid) {
            & {
                flex-wrap: wrap;
                gap: 40px;

                .card {
                    &-stacked {
                        margin: unset;
                        min-width: unset;

                    }
                }
            }

        }
    }
}

.userReview {
    @extend .d-y-center;
    width: 100%;
    max-width: $size-max;
    // background-color: $color-blue-light;
    padding: 64px 52px;
    gap: 32px;
    z-index: 10;


    .tip {
        @extend .font3;
        @extend .tip-section;
        background-color: $color-blue-light;
        color: #287EFF;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .title {
        @extend .font2;
        word-break: normal;
        color: #000;
        text-align: center;

        /* H2 */
        font-family: Poppins;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        /* 50.4px */

        @media screen and (max-width:$breakpoint-small) {
            text-align: center;
            color: #000;
            text-align: center;
            font-family: Poppins;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 120%;
            /* 28.8px */
        }
    }

    .reviewArea {
        @extend .d-x;
        gap: 24px;
        width: 95%;
    }

    .cardContainer {
        @extend .hoverUp2;
    }

    .cardContent {
        @extend .d-y;
        @extend .font5;
        gap: 24px;
        min-width: 480px;
        height: 300px;
        padding: 36px 48px;
        border-radius: 36px;
        background: #FFF;
        box-shadow: 0px 4px 20px -8px rgba(0, 0, 0, 0.16);

        overflow: hidden;
        box-sizing: border-box;
    }

    @media screen and (max-width:$breakpoint-small) {
        .cardContent {
            min-width: 260px;
            padding: 24px 28px;
        }

    }

    .background {
        position: absolute;
        height: 980px;
        width: 96vw;
        background-color: $color-blue-light;
        z-index: -1;
        overflow: hidden;

        .lines {
            z-index: -10;

            @mixin line() {
                width: 2px;
                height: 800px;
                border-radius: 50%;
                position: absolute;
            }

            $gradient1: linear-gradient(to top, rgba(181, 181, 181, 0), rgba(160, 198, 255, 1), rgba(183, 198, 221, 0));
            $gradient2: linear-gradient(to top, rgba(224, 234, 249, 0), rgba(172, 197, 233, 0.85), rgba(181, 181, 181, 0));

            .line1 {
                @include line();
                transform: translateX(8vw) translateY(80px);
                background-image: $gradient1;
            }

            .line2 {
                @include line();
                transform: translateX(22vw) translateY(10px);
                background-image: $gradient2;
                opacity: .5;
            }

            .line3 {
                @include line();
                transform: translateX(42vw) translateY(60px);
                background-image: $gradient2;
                opacity: .8;
            }

            .line4 {
                @include line();
                transform: translateX(66vw) translateY(0px);
                background-image: $gradient1;
            }

            .line5 {
                @include line();
                transform: translateX(85vw) translateY(60px);
                background-image: $gradient2;
            }


        }

        .circle {
            width: max(480px, 48vw);
            height: max(600px, 45vw);
            border-radius: 50%;
            position: absolute;
            top: 5%;
            left: -18%;
            z-index: 1;
            background-image: linear-gradient(to bottom right, rgba(224, 235, 251, 1) 53%, transparent 68%);

            mask-size: 90% 80%;
            mask-image: radial-gradient(transparent 38%, white 39%);
        }
    }
}

.timeline {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 64px;
    // background-color: $color-background-blue-dark;
    background-color: rgba(250, 243, 238, 1);
    // color: $color-white;
    width: calc(100vw - 20px);
    padding: 80px 0;
    z-index: 10;
    min-height: 600px;
    user-select: none;

    .tip {
        @extend .font3;
        @extend .tip-section;
        color: $color-blue-mid;
        background-color: $color-blue-light;
    }

    .title {
        @extend .font2;
        word-break: normal;
    }

    // >.title {
    //     @extend .font3;
    //     word-spacing: 20px;
    //     letter-spacing: 4px;
    // }

    .iconGroup {
        margin-right: 5%;
        display: flex;
        gap: 8px;
        color: $color-gray;
    }

    .content {
        position: relative;

        .arrowNext {
            position: absolute;
            left: 84%;
            top: 36%;
            font-size: 48px;

            >* {
                transition: .5s;

            }

            &:hover {
                >* {
                    transform: translateX(20px);
                }
            }
        }
    }

    .unitGroup {
        display: flex;
        gap: 8px;
        @extend .mask-transition-x-right;

        .timelineUnit {
            display: flex;
            flex-direction: column;
            gap: 28px;

            .timeline-text {
                display: flex;
                flex-direction: column;
                gap: 8px;

                .title {
                    @extend .font4;
                    word-break: normal;
                }

                .content {}
            }



            .timeline-line {
                display: flex;
                align-items: center;
                width: 100%;
                gap: 8px;

                .date {
                    @extend .font3;
                }

                .line {
                    // background-color: $color-white;
                    background-color: $color-gray;
                    width: 100%;
                    height: 2px;

                    .timelineIcon {
                        font-size: 48px;

                        position: relative;
                        transform: translateX(-8px) translateY(-4px) rotate(90deg);
                    }
                }
            }

            .img-container {
                overflow: hidden;
                border-radius: 24px;

            }

            .timeline-img {
                max-width: 600px;
                height: 100%;
                padding-right: 4px;
                transition: .5s;


                cursor: pointer;

                &:hover {
                    transform: scale(1.3);
                }
            }

            &:nth-child(2) {

                clip-path: inset(0px 10% 0px 0px);

            }
        }
    }
}

.cardContainer {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 20px;

    .pricingCard {
        border: 3px solid $color-gray;
        border-radius: 20px;
        padding: 36px 40px;
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: center;
        align-items: center;
        transition: .3s;
        cursor: pointer;

        &:hover {
            box-shadow: 0px 0px 40px 0px rgba(197, 208, 224, 1);
            background-color: $color-blue-light;
            border-color: $color-blue-mid;
            scale: 1.1;
            margin: 0px 20px;

            .btnStart {

                transition: .3s;
                @extend .button-normal-fullfill;
                font-size: 20px;

            }

            .titleGroup {

                word-break: normal;
                color: $color-blue-mid;
            }

            .functionGroup {

                .functionUnit {
                    .group {
                        .text {
                            transition: .3s;
                            @extend .font7;
                        }
                    }
                }
            }
        }

        .titleGroup {
            text-align: center;

            .icon {
                font-size: 32px;
            }

            .text {
                @extend .font7;
            }
        }

        .priceGroup {
            @extend .font2;

            .tagText {
                @extend.font5;
                color: $color-gray;
            }
        }

        .featureGroup {
            li {
                list-style-type: circle;

            }
        }

        .functionGroup {
            display: flex;
            flex-direction: column;
            gap: 24px;

            .functionUnit {
                display: flex;
                align-items: center;
                gap: 10px;

                .icon {
                    font-size: 24px;
                    border: 1px solid $color-blue-mid;
                    color: $color-blue-mid;
                    border-radius: 50%;
                    width: 32px;
                    height: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $color-blue-light;

                }

                .group {
                    max-width: 12vw;

                    .title {
                        @extend .font5;
                        color: $color-gray;
                        word-break: normal;
                    }

                    .text {
                        @extend .font5;
                    }

                    @media screen and (max-width: $size-mid) {
                        max-width: 24vw;
                    }

                    @media screen and (max-width:$breakpoint-small) {
                        max-width: 50vw;
                    }


                }

                &-gray {
                    color: $color-gray;

                    .icon {
                        color: $color-gray;
                        border-color: $color-gray;
                        background-color: transparent;

                    }
                }
            }
        }

        .btnStart {
            @extend .button-normal;
            font-size: 18px;

        }


    }
}