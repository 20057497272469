.userPageContainer {
  >* {
    @extend .Node-base;

    >* {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .brand {
    @extend .d-x-center;
  }

  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    padding: unset;
    gap: 36px;
    width: 100%;

    @media screen and (max-width: $size-mid) {
      margin-top: 50px;
      padding: 0 0;
    }

    .title {
      @extend .font3;

      @media screen and (max-width: $size-mid) {
        font-size: 16px;
      }
    }



    .inputGroup {
      @extend .layout-base;
      width: 60%;
      max-width: 480px;

      @media screen and (max-width: $size-mid) {
        width: 80%;
      }

      .emailInput {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
      }

      .verifyBtn {
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
      }

      .agreementConfirm {
        @media screen and (max-width: $size-mid) {
          font-size: 10px;
        }
      }
    }
  }
}

.signupPage {
  width: 100%;
}

.signupNode {
  width: 100%;
}

.loginPage,
.resetPage {
  width: 100%;
}

.loginNode {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;

  .title {
    @extend .font3;
  }

  .inputGroup {
    @extend .layout-base;

    width: 60%;
    max-width: 480px;

    >* {
      width: 100%;
    }
  }

}

.setPassword {

  width: 100%;
}

.userNode {
  display: flex;
  align-items: center;
  gap: 18px;
  cursor: pointer;
  padding: 4px 16px;
  border-radius: 12px;

  &:hover {
    background-color: $color-gray-dark;
  }

  .userIcon {
    >* {
      width: 32px;
      height: 32px
    }
  }

  .username {
    color: #000;
    text-align: center;

    /* Body 1 */
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 125% */
  }


}

.comfirm {
  display: flex;
  flex-direction: row;
  gap: 20px;

  .checkBtn {
    margin-left: 8px;
  }

  .tag {
    margin: 0 6px;
    color: $color-blue-mid;
    cursor: pointer;
    text-decoration: underline;
  }
}

.privacy-policy {

  color: #333;
  line-height: 1.7;
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;

  h1 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    color: #2d3748;
  }

  h2 {
    font-size: 1.8rem;
    margin-top: 2.5rem;
    margin-bottom: 1rem;
    color: #2d3748;
    border-bottom: 2px solid #e2e8f0;
    padding-bottom: 0.5rem;
  }

  h3 {
    font-size: 1.5rem;
    margin-top: 1.5rem;
    margin-bottom: 0.75rem;
    color: #4a5568;
  }

  p {
    font-size: 1rem;
    margin-bottom: 1rem;
    color: #4a5568;
  }

  ul {
    margin-left: 2rem;
    margin-bottom: 1.5rem;

    li {
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }
  }

  a {
    color: #3182ce;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .contact-info {
    font-style: italic;
    margin-top: 2rem;
  }

  // Ensures formatting from the text file (line breaks, etc.)
  .preformatted {
    white-space: pre-wrap;
  }
}

.terms-of-use {
  color: #333;
  line-height: 1.7;
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;

  h1 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    color: #2d3748;
  }

  h2 {
    font-size: 1.8rem;
    margin-top: 2.5rem;
    margin-bottom: 1rem;
    color: #2d3748;
    border-bottom: 2px solid #e2e8f0;
    padding-bottom: 0.5rem;
  }

  h3 {
    font-size: 1.5rem;
    margin-top: 1.5rem;
    margin-bottom: 0.75rem;
    color: #4a5568;
  }

  p {
    font-size: 1rem;
    margin-bottom: 1rem;
    color: #4a5568;
  }

  ul {
    margin-left: 2rem;
    margin-bottom: 1.5rem;

    li {
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }
  }

  a {
    color: #3182ce;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .contact-info {
    font-style: italic;
    margin-top: 2rem;
  }
}

.contact-us-page {
  display: flex;
  justify-content: space-between;
  width: 100vw;
  max-width: 1000px;
  margin: 5% auto;
  padding: 20px;

  .contact-content {
    flex: 1;
    margin-right: 40px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    @media screen and (max-width: $size-mid) {
      width: 100%;
      margin-right: 0;
    }

    h2 {
      font-size: 24px;
      margin-bottom: 15px;
    }

    p {
      font-size: 16px;
      margin-bottom: 10px;
      line-height: 1.5;
    }

    strong {
      font-weight: bold;
    }
  }

  .contact-form {
    flex: 1;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    h2 {
      font-size: 24px;
      margin-bottom: 15px;
    }
  }

  @media screen and (max-width: $size-mid) {
    flex-direction: column;
    gap: 20px;
    padding: 10% 20px;
    width: 100vw;
    justify-content: center;
    align-items: center;

  }
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* Full viewport height */
  width: 100vw;
  /* Full viewport width */
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.7);
  /* Optional: semi-transparent background */
  z-index: 1000;
  /* Ensure the spinner is above all other content */
}

.unsubscribe-page {
  font-family: Poppins;
}