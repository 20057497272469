.bgdark {
    background-color: $color-dark;
    color: $color-reversedark;
}

.bgpage {
    background-color: $color-bg;
}



.hoverBig:hover {
    transition: scale .3s ease;
    scale: 1.1;
}

.hoverUp {
    * {
        transition: transform .4s ease;
    }

    &:hover {
        * {
            transform: translateY(-6px);
        }
    }
}

.hoverUp2 {
    >* {
        transition: transform .2s ease;
    }

    &:hover {
        >* {
            transform: translateY(-20px);
        }
    }


}

.icon-strokeAnimated {
    stroke: $color-blue-mid;
    stroke-width: 3;
    stroke-linecap: round;
    $time: 4s;

    .all {
        animation: stroke $time ease infinite forwards;
        stroke-dasharray: 100;
        stroke-dashoffset: 100;

        @keyframes stroke {
            60% {
                stroke-dashoffset: 0;
            }

            100% {
                stroke-dashoffset: 0;
            }
        }
    }

    .dot {
        stroke-width: 0;
        opacity: 0;
        animation: dot $time ease infinite forwards;

        @keyframes dot {
            60% {
                stroke-width: 0;
                opacity: 0;
            }

            66% {
                stroke-width: 4.5;
                opacity: 1;
            }

            68% {
                stroke-width: 3;
                opacity: 1;
            }

            100% {
                stroke-width: 3;
                opacity: 1;
            }
        }
    }

}

.underline-gray {
    position: relative;
    &::after {
        content: "";
        position: absolute;
        bottom: -50%;
        width: 100%;
        border-bottom: 1px solid $color-gray-dark;
    }

}
.lineDivider{
    width: 100%;
    border-bottom: 1px solid $color-gray-dark;
}