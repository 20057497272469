.button-light {
    background-color: $color-mid;
    border-radius: $border-radius-small;
    @extend .fontbutton;

    width: 160px;
    height: 50px;

}

.button {
    &-normal {
        background-color: rgba(240, 246, 255, 1);
        border: 1px solid rgba(40, 126, 255, 1);
        padding: 10px 36px;
        border-radius: 64px;
        color: rgba(40, 126, 255, 1);
        cursor: pointer;
        @extend .font3;

        &-fullfill {
            @extend .button-normal;
            z-index: 1;
            background-color: $color-blue-mid;
            color: $color-light;
        }
    }

    &-stepLeft {
        border: 2px solid rgba(203, 213, 225, 1);
        border-radius: 10px;
        padding: 10px 16px;
        display: flex;
        justify-content: center;
        position: relative;
        color: $color-gray-mid;
        @extend .font6;
        min-width: 200px;

        height: 48px;
        cursor: pointer;

        .text {
            text-align: center;
        }

        .tag {
            position: absolute;
            left: 10%;
            top: 18%;
        }
    }

    &-stepRight {
        background-color: $color-blue-mid;
        border-radius: 10px;
        padding: 10px 16px;
        display: flex;
        justify-content: center;
        position: relative;
        color: $color-white;
        @extend .font6;
        min-width: 200px;
        height: 48px;
        cursor: pointer;

        .text {
            text-align: center;
        }

        .tag {
            position: absolute;
            right: 10%;
            top: 18%;
        }
    }

}

.Node {
    &-base {
        padding: 68px 0;
    }
}

.layout {
    &-base {
        display: flex;
        flex-direction: column;
        gap: 28px;
    }
}

.tip-section {

    padding: 10px 32px;
    border: 1px solid rgba(40, 126, 255, 1);
    border-radius: 76px;
    color: $color-blue-mid;
}

.mask-transition-x {
    mask-size: cover;
    mask-image: linear-gradient(to right, transparent 1%, white 8%, white 92%, transparent 99%);

    &-right {
        mask-size: cover;
        mask-image: linear-gradient(to right, white 60%, transparent 80%);
    }
}

.checkboxContainer {
    width: 100%;
    height: 100%;
    position: relative;

    .checkboxTag {
        position: absolute !important;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        opacity: 0;
    }
}

.scrollLoading {
    overflow-x: hidden;
    @extend .x-scroll-4p;

    & &-container {
        @extend .x-scroll-4p-container;
        display: flex;
        flex-direction: row;

        img {
            width: 100%;
        }


    }


}

.card {
    &-display {
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 4px 20px 0px rgba(170, 170, 170, 0.25);
        padding: 28px 32px;
        border-radius: 10px;
    }
}

.QAInputUnit {
    &-textbox {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;

        .Q {
            @extend .font4;
        }

        .A {
            .inputText {
                @extend .textArea;
                scrollbar-width: none;
            }

        }
    }

    &-selectTextBox {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
        position: relative;

        .Q {
            .inputText {
                width: 100%;
            }
        }


    }

    &-slider {
        @extend .baseItem;

        .A {
            display: flex;
            gap: 60px;
            position: relative;

            .showNumber {
                position: absolute;
                top: -20px;
                left: 46%;
                color: $color-blue-mid;
            }
        }

    }
}

.textArea {
    width: 100%;
    border: 1px solid $color-gray-dark;
    padding: 12px 16px;
    min-height: 120px;
    border-radius: 12px;

    &::placeholder {
        color: $color-gray;
    }
}

.inputText {
    width: 100%;
    border: 2px solid $color-gray-dark;
    border-radius: 8px;
    padding: 8px 12px;

}

.inputSlider {
    width: 100%;

}

.brandNode {
    .img {
        width: 200px;
        height: 36px;

        >* {
            width: 100%;
        }
    }
}

.selectWithOther {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    position: relative;

    .question {
        @extend .font4;
    }

    .selectInput {
        .selectInputBox {
            @extend .font4;

        }

    }
}

.antdQAinput {
    @extend .baseContent;

    .antdInputBox {
        height: 48px;
        @extend .font4;
    }

    .antdQuestion {
        @extend .font4;
    }
}

.chooseCard {
    @extend .card-display;
    padding: 20px 40px 40px 40px;
    color: $color-blue-dark;
    word-wrap: normal;
    border: 3px solid transparent;
    position: relative;
    cursor: pointer;

    &:hover {
        color: $color-blue-mid;
        border-color: $color-blue-mid;

        .tag {
            display: inline-block;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;

        .icon {
            font-size: 80px;
        }

        .text {
            text-align: center;
            @extend .font3;
            font-size: 20px;
        }
    }

    .tag {
        position: absolute;
        font-size: 48px;
        top: -40px;
        left: 0;
        width: 100%;
        text-align: center;
        display: none;
        z-index: 10;

        &::after {
            content: "";
            width: 36px;
            height: 36px;
            border-radius: 50%;
            background-color: white;
            position: absolute;
            top: 18px;
            left: 45%;
            z-index: -1;
        }
    }
}