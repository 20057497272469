//颜色
// $color-bg: rgb(248, 248, 248);
$color-bg: rgb(248, 248, 248);
$color-dark: rgb(39, 50, 64);
$color-white: rgb(248, 248, 248); //$color-semidark:rgb(248, 254, 250);
$color-mid: rgb(170, 170, 170);
$color-input: rgba(71, 85, 105, 1);

$color-gray: rgb(170, 170, 170);
$color-gray-mid: rgba(124, 124, 124, 1);
$color-gray-dark: rgba(200, 203, 217, 1);

$color-blue-dark: rgb(56, 63, 145);
$color-blue-mid: rgb(40, 126, 255);
$color-blue-light: rgb(240, 246, 255);

$color-background-blue-dark: rgba(8, 40, 87, 1);

$color-red: rgba(255, 59, 48, 1);


$color-semilight: rgb(92, 167, 155);
$color-light: rgba(240, 246, 255, 1);
$color-reversedark: rgb(254, 255, 254);
$color-reverselight: rgb(18, 18, 18);
//边框、圆角、阴影
$border-size: 1px;
$border-radius: 16px;
$border-radius-small: 10px;
$box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

//响应式
$size-min: 260px;
$size-mid: 960px;
$size-max: 1400px;

$breakpoint-mid: 1080px;
$breakpoint-small: 768px;
$padding: calc(1vw + 10px);
$padding-2: calc(1vw + 20px);

$gap: calc(2vw + 20px);