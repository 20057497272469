.TutorFilter {
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: start;
    font-family: 'Poppins';
    width: 30%;
    max-height: calc(100vh - 190px);
    overflow-y: auto;
    scrollbar-width: thin;

    @media screen and (max-width: $breakpoint-small) {
        width: 100%;
    }

    &-searchSection {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
        padding: 16px 16px;
        border: 1px solid $color-mid;
        border-radius: 16px;

        @media screen and (max-width: $breakpoint-small) {
            padding: 8px;
            gap: 4px;
        }

        label {
            color: #1F384C;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 23px;
            /* 127.778% */
            letter-spacing: 0.5px;

            @media screen and (max-width: $breakpoint-small) {
                font-size: 12px;
            }
        }

        .searchComponent {
            display: flex;
            flex-direction: row;
            width: 100%;
            gap: 5px;

            .searchInput {
                width: 100%;
                min-height: 40px;
                font-size: 12px;
                word-break: break-all;
                border-radius: 8px;
                border: 1px solid var(--Border-1, #C8CBD9);

                &:focus {
                    outline: none;
                }
            }

            .searchIcon-container {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 38px;
                height: auto;
                border: 1px solid $color-gray;

                border-radius: 8px;
                font-size: 24px;
                font-weight: 600;

                &:hover {
                    background-color: $color-blue-mid;
                    color: $color-white;
                    cursor: pointer;
                }
            }
        }


    }

    &-filterSection {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
        padding: 16px 16px;
        border: 1px solid $color-gray-mid;
        border-radius: 16px;
        font-family: 'Poppins';

        .filterTitle {
            display: flex;
            justify-content: space-between;
            align-items: center;

            label {
                color: #1F384C;
                font-family: Poppins;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 23px;
                /* 127.778% */
                letter-spacing: 0.5px;

                @media screen and (max-width: $breakpoint-small) {
                    font-size: 12px;
                }
            }

            .ClearAllContainer {
                display: flex;
                align-items: center;
                gap: 6px;
                width: fit-content;

                .ClearAllSpan {
                    color: var(--Icon_1, #AAA);
                    font-family: Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                    /* 183.333% */
                    letter-spacing: 0.5px;

                }

                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }

                #clearAllIcon {
                    width: 20px;
                    height: 20px;
                    color: var(--Icon_1, #AAA)
                }
            }
        }

        .filterBody {
            .MajorComponent {
                display: flex;
                flex-direction: column;
                gap: 12px;
                padding: 12px 0;
                border-top: 1px solid var(--Border-1, #C8CBD9);
                transition: all 0.3s ease;


                &-title {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    font-size: 16px;
                    color: #7C7C7C;

                    span {
                        font-family: Poppins;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 22px;
                        /* 137.5% */
                        letter-spacing: 0.5px;
                    }

                    &:hover {
                        text-decoration: underline;
                        cursor: pointer;
                    }

                    .countingBlock {
                        display: flex;
                        width: 16px;
                        height: 16px;
                        padding: 2px 2px;
                        justify-content: center;
                        align-items: center;
                        border-radius: 4px;
                        background: #AAA;
                        color: #FFF;
                        font-family: Poppins;
                        font-size: 12px;
                    }
                }

                &-body {
                    display: flex;
                    flex-direction: column;
                    gap: 12px;

                    max-height: 200px;
                    overflow-y: scroll;
                    scrollbar-width: none;

                    .majorTypelabel {
                        font-size: 16px;
                        text-decoration: underline;
                    }

                    .majorOption {
                        display: flex;
                        gap: 4px;
                        align-items: center;

                        label {
                            font-size: 14px;
                            word-break: break-all;
                            display: flex;
                            gap: 4px;

                            &:hover {
                                text-decoration: underline;
                                cursor: pointer;
                            }
                        }
                    }
                }


            }

            .SliderComponent {
                display: flex;
                flex-direction: column;
                gap: 12px;
                padding: 12px 0;
                border-top: 1px solid var(--Border-1, #C8CBD9);
                transition: all 0.3s ease;
                color: #7C7C7C;


                &-title {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    font-size: 16px;
                    color: #7C7C7C;

                    span {
                        font-family: Poppins;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 22px;
                        /* 137.5% */
                        letter-spacing: 0.5px;
                    }

                    &:hover {
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }

                &-body {

                    padding: 10px 5px;

                    .slider-container {
                        display: flex;
                        flex-direction: column;
                        width: 100%;

                        .track {
                            height: 6px;
                            border-radius: 3px;
                            background: #ccc;
                            margin: 20px 0;
                            position: relative;
                        }

                        .Tutor-thumb {
                            height: 20px;
                            width: 20px;
                            border-radius: 50%;
                            background-color: $color-blue-mid;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            position: relative;

                            &-label {
                                position: absolute;
                                top: -25px;
                                /* Adjust this to fit the label better */
                                color: #000;
                                font-weight: bold;
                                font-size: 12px;
                                white-space: nowrap;
                            }
                        }
                    }
                }
            }
        }

        .filterSubmit {
            color: #FFF;
            font-family: Poppins;
            font-size: 18px;
            font-weight: 500;
            line-height: 23px;
            letter-spacing: 0.5px;
            display: flex;
            padding: 9px 0px;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            border: 2px solid transparent;
            background: var(--Main1, #287EFF);

            &:hover {
                border: 2px solid black;
            }
        }
    }
}

.TutorResourcePage {
    display: flex;
    flex-direction: column;
    word-break: break-all;

    @media screen and (max-width: $breakpoint-small) {
        padding: 8px;

    }

    .TutorResourceHead {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 26px;
        width: 100%;

        @media screen and (max-width: $breakpoint-small) {
            flex-direction: column;
            gap: 10px;
        }

        .TutorResourceLabel {
            font-family: 'poppins';
            font-size: 18px;
            font-weight: 500;
            line-height: 23px;
            letter-spacing: .5px;

            @media screen and (max-width: $breakpoint-small) {
                font-size: 16px;
            }

        }

        .TutorResourceWishlist {
            display: flex;
            gap: 9px;
            align-items: center;
            justify-content: start;

            label {
                width: fit-content;
                color: #7C7C7C;
                font-family: "Poppins";
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.5px;

                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }

                @media screen and (max-width: $breakpoint-small) {
                    font-size: 12px;
                }
            }

            .bookmarkIcon {
                height: 16px;
                width: 16px;
                color: $color-blue-mid;

                @media screen and (max-width: $breakpoint-small) {
                    display: none;
                }
            }
        }


    }

    .TutorResourceBody {
        display: flex;
        flex-direction: row;
        gap: 30px;

        @media screen and (max-width: $breakpoint-small) {
            flex-direction: column;
        }



        .TutorResourceCardContainer {
            display: flex;
            width: 100%;
            flex-direction: column;
            gap: 20px;
            max-height: calc(100vh - 190px);
            overflow-y: scroll;


            .pagination {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 20px;
                font-size: 16px;

                @media screen and (max-width: $breakpoint-small) {
                    font-size: 12px;
                }

                span {
                    margin: 0 5px;
                    padding: 5px 10px;
                    cursor: pointer;
                    color: #1a73e8; // Blue color similar to Google's

                    @media screen and (max-width: $breakpoint-small) {
                        padding: 5px 3px;
                        margin: 0 2px;
                    }

                    &.active {
                        font-weight: bold;
                        text-decoration: underline;
                    }

                    &:hover {
                        text-decoration: underline;
                    }

                    &.next,
                    &.prev {
                        font-size: 18px;
                        color: #1a73e8;
                    }

                    &.ellipsis {
                        cursor: default;
                        text-decoration: none;
                    }
                }
            }

        }
    }
}

.TutorDetailCard {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    height: auto;
    padding: 16px 23px;
    border: 1px solid #CCCCCC;
    border-radius: 16px;
    background-color: #FAFAFA;
    font-family: 'poppins';
    gap: 28px;

    &_pic {

        img {
            width: 163px;
            height: 196px;
        }

    }

    &_info {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 20px;

        &_name {
            color: #1F384C;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 23px;
            letter-spacing: 0.5px;
        }

        &_row2 {
            display: flex;
            flex-direction: row;
            gap: 13px;
            align-items: center;

            .dot {
                width: 5px;
                height: 5px;
                background-color: black;
                border-radius: 50%;
                display: inline-block;
            }

            &_location {
                display: flex;
                gap: 6px;
                align-items: center;


                span {
                    color: var(--Font_1, #273240);
                    font-family: Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    letter-spacing: 0.5px;
                }
            }

            &_rate {
                display: flex;
                gap: 6px;
                align-items: center;

                .rate-star {
                    color: orange;
                }

                .ratescore {
                    color: var(--Font_1, #273240);
                    font-family: Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 20px;
                    /* 166.667% */
                    letter-spacing: 0.5px;
                }
            }

            .studentNumber {
                color: var(--Font_1, #273240);
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                /* 166.667% */
                letter-spacing: 0.5px;
            }

            .expandButton {
                color: var(--Icon_1, #AAA);
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                /* 166.667% */

                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }

        &_labelarea {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            align-content: flex-start;
            gap: 5px 9px;
            align-self: stretch;


            &_bubble {
                display: flex;
                padding: 2px 12px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 8px;
                border: 1px solid #DAE4F9;
                background: #EAEEF9;

                span {
                    color: var(--Font_1, #273240);
                    font-family: Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 20px;
                    /* 166.667% */
                }
            }
        }

        &_button {
            display: flex;
            width: 95%;
            justify-content: end;

            button {
                display: flex;
                padding: 6px 20px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 10px;
                border: 1px solid var(--Font_1, #273240);
                background: var(--Font_1, #273240);
                color: var(--Font_3, #F8F8F8);
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
            }
        }
    }


}

.TutorEmailPopup {
    display: flex;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10000;

    &_EmailContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: min(80%, 913px);
        height: fit-content;
        padding: 50px;
        gap: 36px;
        flex-shrink: 0;
        border-radius: 25px;
        background: #FFF;
        font-family: Poppins;

        @media screen and (max-width: $breakpoint-small) {
            padding: 10px;

        }

        label {
            color: #1F384C;
            text-align: center;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 23px;
            /* 127.778% */
            letter-spacing: 0.5px;
        }

        .EmailSendInfo {
            display: flex;
            flex-direction: column;
            gap: 22px;
            width: 100%;
            align-items: start;
            font-size: 16px;
            font-weight: 500;

            &_To {
                width: 100%;
                border-bottom: 1px solid #C8CBD9;

                input {
                    border: none;

                    &:focus {
                        outline: none;
                    }
                }
            }

            &_From {
                width: 100%;
                border-bottom: 1px solid #C8CBD9;

                input {
                    border: none;

                    &:focus {
                        outline: none;
                    }
                }
            }

            &_EmailBody {
                display: flex;
                width: 100%;
                min-height: 50%;
                padding: 32px 36px;
                align-items: flex-start;
                border-radius: 8px;
                border: none;
                background: var(--Font_3, #F8F8F8);
                color: var(--Font_1, #273240);
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                /* 157.143% */
                letter-spacing: 0.5px;

                &:focus {
                    outline: none;
                }
            }

        }

        .SendButton {
            display: flex;
            width: 100%;
            justify-content: space-between;
            padding: 0 32px;

            @media screen and (max-width: $breakpoint-small) {
                flex-direction: column;
                gap: 10px;

            }

            button {
                display: flex;
                padding: 6px 20px;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                background-color: white;
                border: 1px solid black;

                &:hover {
                    background-color: #273240;
                    color: white;
                }
            }
        }
    }
}