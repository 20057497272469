.wordBreak {
    word-break: break-all;
}

.content-txt {
    &-title {
        @extend .font6;
        color: $color-dark;
    }

    &-normal {
        @extend .font7;
        color: $color-gray-mid;
    }

    &-date {
        @extend .font5;
        color: $color-gray
    }

    &-tip {
        @extend .font5;
        color: $color-dark;

    }

    &-input {
        @extend .font5;
        color: $color-input;
    }
}