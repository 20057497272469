.faq {
  @extend .d-y-center;
  width: 100vw;
  position: relative;
  gap: 32px;
  z-index: 10;

  //background: linear-gradient(180deg, #F8F8F8 0%, #F0F6FF 100%);
  @media screen and (max-width:$breakpoint-small) {
    padding: 0 16px;
  }

  .tip {
    @extend .font3;
    @extend .tip-section;
    color: #287EFF;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border-radius: 76px;
    border: 1px solid #287EFF;
    background: #F0F6FF;
    color: #287EFF;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    @media screen and (max-width:$breakpoint-small) {
      font-size: 14px;
      text-align: center;
    }
  }

  .title {
    @extend .font2;
    // color: $color-blue-mid;
    word-break: normal;
    color: #000;
    text-align: center;

    /* H2 */
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    /* 50.4px */

    @media screen and (max-width: $size-mid) {
      font-size: 36px;
    }

    @media screen and (max-width:$breakpoint-small) {
      font-size: 24px;
      text-align: center;
    }

  }

  .faqContainer {
    max-width: 1137px;
    width: 100%;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;

  }

  .questionCardContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    max-width: $size-max;
    height: fit-content;
    padding: 36px 40px 36px 20px;
    border-radius: $border-radius;
    gap: 24px;
    border-radius: 24px;
    border: 1px solid transparent;
    background: rgba(217, 217, 217, 0.10);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.10);

    @media screen and (max-width: $size-mid) {
      padding: 20px 20px 20px 20px;
      font-size: 24px;
    }

  }

  .questionCardContainer:hover {
    * {
      transition: transform .4s ease;
    }

    cursor: pointer;
    border: solid 1px $color-blue-mid;

    .arrow {
      color: $color-blue-mid;
    }
  }

  .questionContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

  }

  .question {
    @extend .font4;
    color: #000;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    /* 125% */

    @media screen and (max-width: $size-mid) {
      font-size: 16px;
    }

    @media screen and (max-width:$breakpoint-small) {
      color: #000;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;
      /* 158.333% */
    }

  }

  .arrow {
    font-size: 21px;
    color: #8C8C8C;
  }

  .answerContainer {
    @extend .font4;

    @media screen and (max-width: $size-mid) {
      font-size: 16px;
    }

    @media screen and (max-width:$breakpoint-small) {
      color: #000;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;
      /* 158.333% */
    }
  }

  .ring {
    width: 50vw;
    height: 50vw;
    max-width: 680px;
    max-height: 680px;
    position: absolute;
    top: 122px;
    left: -20%;
    border-radius: 50%;
    border: 2px solid transparent;
    background: linear-gradient(172.41deg, #98C1FF 3.7%, #5B7499 12.83%, #FFFFFF 35.85%, #98C1FF 97.3%);
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  .dot1 {
    width: 36px;
    height: 36px;
    position: absolute;
    top: 242px;
    left: 127px;
    gap: 0px;
    opacity: 0.1;
    background: rgba(40, 126, 255, 1);
    border-radius: 50%;
  }

  .dot2 {
    position: absolute;
    width: 18px;
    height: 18px;
    top: 136px;
    right: 30px;
    gap: 0px;
    opacity: 0.2;
    background: rgba(40, 126, 255, 1);
    border-radius: 50%;
  }

  .dot3 {
    position: absolute;
    width: 36px;
    height: 36px;
    top: 652px;
    left: 1327px;
    gap: 0px;
    opacity: 0.1;
    background: rgba(40, 126, 255, 1);
    border-radius: 50%;

  }

  .dot4 {
    position: absolute;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    top: 789px;
    left: 91px;
    gap: 0px;
    opacity: 0.1;
    background: rgba(40, 126, 255, 1);
  }
}

.footNav {
  @extend .d-y-center;
  width: 85%;
  max-width: $size-max;
  gap: 35px;
  z-index: 10;

  @media screen and (max-width: $size-mid) {
    gap: 24px;
  }

  .footNav-logo-container {
    width: 85%;
    max-width: $size-max;
    display: flex;
    justify-content: start;

    .footNav-logo {
      width: 73px;
      height: 77.9px;

      @media screen and (max-width: $size-mid) {
        width: 40px;
        height: 43.3px;
      }
    }
  }

  .footNav-item {
    //没有这个字体目前
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }

  }

  .footNav-links-container {
    width: 85%;
    max-width: $size-max;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;

    @media screen and (max-width: $size-mid) {
      flex-direction: column;
      gap: 10px;
    }


    .footNav-slogan {
      width: 45%;
      @extend .font2;
      line-height: 1.3;
      word-break: normal;

      @media screen and (max-width: $size-mid) {
        width: 85%;
        font-size: 30px;
      }
    }

    .footNav-linkLists {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 20px;
      font-size: 16px;

      @media screen and (max-width:$breakpoint-small) {
        flex-direction: column;
      }

      .footNav-linkList {
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        gap: 10px;
        padding: 10px 0px;

        @media screen and (max-width: $size-mid) {
          flex-direction: column;
          gap: 5px;
        }
      }
    }


  }

  .footNav-socials {
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 85%;
    max-width: $size-max;
    gap: 20px;
    margin-top: 20px;

    //icons
    i {
      font-size: 24px;

      &:hover {
        cursor: pointer;
      }
    }

    img {
      margin-top: 3px;
      height: 30px;
      width: 30px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.carousel {
  position: relative;
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: center;
  align-items: center;

  .tip {
    @extend .font3;
    @extend .tip-section;
  }

  .title {
    @extend .font2;
    word-break: normal;

    @media screen and (max-width: $size-mid) {
      font-size: 36px;
    }

    @media screen and (max-width: $breakpoint-small) {
      font-size: 28px;
      text-align: center;
    }
  }

  .imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px; // Space between images
    overflow: hidden;
    width: 100%;
    height: 400px; // Set a consistent height for the carousel
    margin-top: 32px;
  }

  .image {
    flex-shrink: 0;
    width: auto; // Allow width to adjust based on the aspect ratio
    height: 100%; // Ensure the image fills the container's height
    object-fit: contain; // Fully display the image within its container
    transition: transform 0.5s ease, opacity 0.5s ease, filter 0.5s ease;
    border-radius: 10px;

    &.centerImage {
      transform: scale(1.1); // Slightly enlarge the center image
      z-index: 2;
      opacity: 1;
      filter: none; // No blur for the center image
    }

    &.sideImage {
      transform: scale(0.9); // Shrink the side images
      opacity: 0.5; // Make side images semi-transparent
      filter: blur(4px); // Add a blur effect to the side images
    }
  }

  .button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
    z-index: 3;

    &.buttonLeft {
      left: -50px;
    }

    &.buttonRight {
      right: -50px;
    }
  }

  /* Responsive Design */
  @media (max-width: 768px) {
    // Tablet and small devices
    width: 90%;
    height: fit-content;

    .imageContainer {
      gap: 10px; // Reduce spacing between images
      height: 300px; // Reduce height for smaller screens
    }

    .image {
      &.centerImage {
        transform: scale(1.2); // Further enlarge the center image
      }

      &.sideImage {
        filter: blur(6px); // Add stronger blur effect for side images
        opacity: 0.4; // Increase transparency for side images
      }
    }

    .button {
      width: 30px;
      height: 30px;

      &.buttonLeft {
        left: -20px;
      }

      &.buttonRight {
        right: -20px;
      }
    }
  }

  @media (max-width: 480px) {

    // Mobile phones
    .imageContainer {
      gap: 5px; // Further reduce spacing between images
      height: 250px; // Adjust height for mobile
    }

    .image {
      &.centerImage {
        transform: scale(1.3); // Further enlarge the center image
      }

      &.sideImage {
        filter: blur(8px); // Stronger blur effect for mobile
        opacity: 0.3; // Increase transparency for side images
      }
    }

    .button {
      display: none; // Hide buttons for better usability on small screens
    }
  }
}

.subcribeSection {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: fit-content;
  align-self: stretch;
  background: #1e71ee;

  @media screen and (max-width:$breakpoint-small) {
    display: flex;
    padding: 40px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
  }


  &-container {
    display: flex;
    flex-direction: column;
    display: flex;
    max-width: 1402px;
    width: 80%;
    height: 451px;
    padding: 61px 77px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @media screen and (max-width:$breakpoint-small) {
      width: 100%;
      padding: 0;
      height: fit-content;

    }

    .title {
      color: #FFF;

      /* H2 */
      font-family: Poppins;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      /* 50.4px */
      margin-bottom: 50px;

      @media screen and (max-width:$breakpoint-small) {
        color: #FFF;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 120%;
        /* 28.8px */
      }


    }

    .subtitle {
      color: #FFF;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 25px;
      /* 125% */
      margin-bottom: 40px;

      @media screen and (max-width:$breakpoint-small) {
        color: #FFF;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        /* 133.333% */
      }
    }

    &-form {
      display: flex;
      gap: 20px;
      width: 100%;

      @media screen and (max-width:$breakpoint-small) {
        flex-direction: column;
      }

      input {
        width: 65%;
        display: flex;
        max-width: 838px;
        height: 65px;
        padding: 18px 28px;
        border-radius: 12px;
        background: #FFF;
        border: none;

        @media screen and (max-width:$breakpoint-small) {
          width: 100%;
          display: flex;
          height: 48px;
          padding: 18px 28px;
          align-items: center;
          gap: 10px;
          align-self: stretch;
        }
      }

      button {
        border-radius: 12px;
        background: #BAD3FA;
        max-width: 399px;
        width: 30%;
        height: 65px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 12px;
        color: #000;
        text-align: center;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        /* 28px */
        border: none;

        @media screen and (max-width:$breakpoint-small) {
          width: 100%;
          display: flex;
          height: 48px;
          padding: 18px 15px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          align-self: stretch;
        }
      }
    }



  }
}

.featureFirst {
  display: flex;
  padding: 50px 0px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  align-self: stretch;

  .tip {
    display: flex;
    width: 190px;
    height: 57px;
    padding: 10px 32px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 76px;
    border: 1px solid #287EFF;
    background: #F0F6FF;
    color: #287EFF;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .navibar {
    display: flex;
    align-items: center;
    gap: 190px;
    margin-bottom: 4px;

    &-item {
      display: flex;
      padding: 10px 20px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-bottom: 4px solid #000;
      opacity: 0.3;

      /* H2 */
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      /* 50.4px */
      cursor: pointer;

      &.active {
        border-bottom: 4px solid #287EFF;
        color: #287EFF;
        opacity: 1;

      }
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: auto;
    max-width: 1538px;
    max-height: 905px;
    width: 90%;
    height: fit-content;
    flex-shrink: 0;
    padding: 5%;
    border-radius: 45px;
    background: #F0F6FF;
    box-shadow: 0px 0px 30px -8px #287EFF inset;
    gap: 40px;


    .title {
      color: #000;

      /* H2 */
      font-family: Poppins;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      /* 50.4px */
    }

    .mainContent {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      gap: 30px;
      justify-content: space-between;

      .steplist {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 50%;

        &-item {
          display: flex;
          width: 100%;
          padding: 25px 27px;
          align-items: flex-start;
          gap: 17px;
          border-radius: 20px;
          border: 1px solid #D7D7D7;
          background: #FFF;

          &-icon {
            border-radius: 50%;
            background: #287EFF;
            color: white;
            width: 51px;
            height: 51px;
            display: flex;
            align-items: center;
            justify-content: center;

            .icon {
              display: flex;
              width: 51px;
              height: 51px;
              padding: 13px;
              align-items: center;
            }
          }

          &-content {
            display: flex;
            flex-direction: column;
            gap: 14px;

            .title {
              color: #000;
              font-family: Poppins;
              font-size: 20px;
              font-style: normal;
              font-weight: 600;
              line-height: 25px;
              /* 125% */
            }

            .content {
              color: #000;
              font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
              /* 137.5% */
            }
          }
        }

        button {
          display: inline-flex;
          padding: 16px 48px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 12px;
          background: #287EFF;
          width: 40%;
          align-self: end;
          border: none;
          color: #FFF;
          text-align: center;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
          /* 24px */
        }

      }

      img {
        width: 50%;
        aspect-ratio: 709 / 454;
        /* Maintain the original aspect ratio */
        //flex-shrink: 0;
        max-width: 709px;
        max-height: 454px;
        flex-shrink: 0;
      }
    }

  }

  .samllPageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 36px;

    &-item {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 20px;
      min-width: 360px;
      width: 80%;
      height: fit;
      padding: 30px 10px;
      flex-shrink: 0;
      border-radius: 20px;
      background: #F0F6FF;
      box-shadow: 0px 0px 20px -12px #287EFF inset;

      .title1 {
        color: #287EFF;
        text-align: center;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        /* 33.6px */
      }

      .title2 {
        color: #000;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        word-break: keep-all;
      }

      img {
        height: 210px;
        align-self: stretch;
      }

      .steplist {
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: start;

        &-item {
          display: flex;
          gap: 10px;

          &-icon {
            display: flex;
            width: 39px;
            height: 39px;
            padding: 13px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 25.5px;
            background: #287EFF;

            .icon {
              width: 20px;
              height: 20px;
              flex-shrink: 0;
              color: white;
            }
          }

          &-content {
            display: flex;
            flex-direction: column;
            gap: 12px;

            .title {
              color: #000;
              font-family: Poppins;
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 25px;
              /* 156.25% */
            }

            .content {
              color: #000;
              font-family: Poppins;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }


          }


        }

        button {
          display: flex;
          width: 164px;
          height: 48px;
          padding: 16px 24px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 12px;
          background: #287EFF;
          align-self: center;
          border: none;
          color: #FFF;
          color: #FFF;
          text-align: center;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 150%;
          /* 24px */
        }
      }
    }
  }
}

.schoollist {
  display: flex;
  width: 100%;
  background: #FFF;
  overflow-x: hidden;

  .sroll-container {
    display: flex;
    width: 100vw;
    height: fit-content;
    padding: 26px 72px;
    align-items: center;
    gap: 120px;
    animation: xscroll-continous-3 18s forwards linear infinite normal;

    img {
      width: 200px;
      height: auto;
      flex-shrink: 0;
    }

  }



}