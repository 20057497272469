.WhislistPickDetail-card {
    position: relative;
    width: 100%;
    height: auto;
    padding: 20px 20px;
    border: 1px solid #CCCCCC;
    border-radius: 16px;
    background-color: #FAFAFA;
    font-family: 'poppins';

    &:hover {
        background-color: $color-blue-light;
        cursor: pointer;
    }

    @media screen and (max-width: $breakpoint-small) {
        padding: 20px 10px;
    }


    .program-header {
        display: flex;
        align-items: start;
        justify-content: space-between;

        .logo {
            width: 43px;
            height: 43px;
            margin-right: 20px;
            border-radius: 10px;

            @media screen and (max-width: $breakpoint-small) {
                display: none;
            }
        }

        .school-info {
            flex-grow: 1;

            .course-name {
                font-size: 18px;
                font-weight: 500;
                margin: 0;

                @media screen and (max-width: $breakpoint-small) {
                    font-size: 14px;
                }
            }

            .school-name {
                font-size: 16px;
                color: #7c7c7c;
                font-weight: 600;

                @media screen and (max-width: $breakpoint-small) {
                    font-size: 12px;
                }
            }
        }

        .wishlistbookmark-container {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px 5px;
            border: 1px solid $color-gray;
            border-radius: 12px;
            font-size: 32px;

            &:hover {
                cursor: pointer;
                background-color: $color-blue-light;
            }

            @media screen and (max-width: $breakpoint-small) {
                font-size: 24px;
            }

            #wishlist-icon {
                color: $color-blue-mid;
            }
        }

        .wishlistClose-container {
            display: flex;
            justify-content: end;
            align-items: start;
            font-size: 24px;

            &:hover {
                cursor: pointer;
                color: $color-blue-mid;
            }

            @media screen and (max-width: $breakpoint-small) {
                font-size: 24px;
            }
        }

        .PickCircle {
            width: 30px;
            height: 30px;
            background-color: #ccc;
            /* Default background color when not picked */
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            transition: background-color 0.3s ease;
            /* Smooth transition for background color */

            &.picked {
                background-color: #007bff;
                /* Blue background when picked */
            }

            .iconify {
                color: white;
                /* Set the color of the icon to white */
            }
        }


    }

    .tags {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-bottom: 10px;

        @media screen and (max-width: $breakpoint-small) {
            flex-wrap: wrap;
            gap: 5px;
        }

        .tag {
            background-color: #e0e0e0;
            padding: 5px 10px;
            border-radius: 12px;
            font-size: 13px;
            color: #555;
            width: fit-content;

            @media screen and (max-width: $breakpoint-small) {
                padding: 5px 5px;
                font-size: 10px;
                font-weight: 600;
            }
        }
    }

    .expand-toggle {
        background: none;
        border: none;
        color: #007bff;
        cursor: pointer;
        font-size: 14px;


        &:hover {
            text-decoration: underline;
        }
    }

    .overview,
    .details,
    .admission {
        margin-top: 10px;

        h2 {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 10px;
            border-bottom: 1px solid #CCCCCC;

            @media screen and (max-width: $breakpoint-small) {
                font-size: 14px;
            }
        }

        p {
            font-size: 14px;
            line-height: 1.5;
            color: #333;

            @media screen and (max-width: $breakpoint-small) {
                font-size: 12px;
            }
        }

        ul,
        ol {
            padding-left: 20px;
            margin: 0;
            font-size: 14px;

            li {
                margin-bottom: 8px;
                color: #555;

                @media screen and (max-width: $breakpoint-small) {
                    font-size: 12px;
                    margin-bottom: 4px;
                }
            }
        }

        ul {
            list-style-type: disc;
        }

        ol {
            list-style-type: decimal;
        }
    }

    .details {
        display: flex;
        gap: 20px;

        @media screen and (max-width: $breakpoint-small) {
            flex-direction: column;
        }

        section {
            flex: 1;
        }
    }
}

.programWishlistPick {
    display: flex;
    flex-direction: row;
    height: calc(100vh - 295px);

    .LeftPad {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 60%;
        padding-right: 24px;
        border-right: 1px solid #C8CBD9;

        @media screen and (max-width: $breakpoint-small) {
            border-right: none;
            width: 100%;
            padding: 0 0;
        }

        &-heading {
            display: flex;
            justify-content: space-between;
            align-items: center;

            label {
                color: #1F384C;
                font-family: Poppins;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 23px;
                /* 127.778% */
                letter-spacing: 0.5px;
            }

            span {
                color: #1F384C;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 23px;
                /* 164.286% */
                letter-spacing: 0.5px;

                &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }

        &-content {
            display: flex;
            flex-direction: column;
            gap: 16px;
            height: calc(100vh - 100px);
            overflow-y: scroll;
            scrollbar-width: thin;
        }
    }

    .Rightpad {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 37%;

        @media screen and (max-width: $breakpoint-small) {
            display: none;
        }

        &-heading {
            display: flex;
            justify-content: start;
            gap: 10px;
            align-items: center;

            label {
                color: #1F384C;
                font-family: Poppins;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 23px;
                /* 127.778% */
                letter-spacing: 0.5px;
            }

            span {
                color: #1F384C;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 23px;
                /* 164.286% */
                letter-spacing: 0.5px;
                opacity: 0.6;
            }
        }

        &-content {
            display: flex;
            flex-direction: column;
            gap: 16px;
            height: calc(100vh - 100px);
            overflow-y: scroll;
            scrollbar-width: thin;

        }
    }
}

.PersonalizationPage {
    height: calc(100vh - 300px);
    display: flex;
    flex-direction: row;


    .tagContainer {
        display: flex;
        width: fit-content;
        align-items: center;
        gap: 10px;
        padding: 4px 12px;
        border-radius: 6px;
        border: 1px solid #C8CBD9;
        color: #000;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.5px;

        &:hover {
            cursor: pointer;
            background-color: #DAE4F9;
            text-decoration: underline;
        }

        .tagCloseIcon {
            height: 16px;
            width: 16px;
            cursor: pointer;
        }
    }

    .LeftPad {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 60%;
        padding-right: 24px;
        border-right: 1px solid #C8CBD9;

        &-heading {
            display: flex;
            justify-content: space-between;
            align-items: center;

            label {
                color: #1F384C;
                font-family: Poppins;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 23px;
                letter-spacing: 0.5px;
            }
        }

        &-content {
            display: flex;
            flex-direction: column;
            gap: 16px;
            height: calc(100vh - 100px);
            overflow-y: scroll;
            scrollbar-width: thin;

            .leftpadItem {
                display: flex;
                flex-direction: column;
                padding: 20px 32px;
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;
                border: 1px solid transparent;
                border-radius: 12px;
                background: #FAFAFA;


                &:hover {
                    cursor: pointer;
                    border: 1px solid var(--Main, #287EFF);
                }

                &.selected {
                    border: 1px solid var(--Main, #287EFF);
                }

                label {
                    color: #1F384C;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 23px;
                    letter-spacing: 0.5px;
                }

                span {
                    color: #1F384C;
                    font-family: Poppins;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 23px;
                    /* 164.286% */
                    letter-spacing: 0.5px;
                }

                .answerContainer {
                    display: flex;
                    gap: 14px;
                    flex-wrap: wrap;

                    .rankingAnswer {
                        color: $color-blue-mid;
                    }
                }
            }
        }
    }

    .Rightpad {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 40%;
        padding-left: 24px;

        &-heading {
            visibility: hidden;
            display: flex;
            justify-content: start;
            gap: 10px;
            align-items: center;

            label {
                color: #1F384C;
                font-family: Poppins;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 23px;
                letter-spacing: 0.5px;
            }
        }

        &-content {
            display: flex;
            flex-direction: column;
            gap: 16px;
            height: calc(100vh - 100px);
            overflow-y: scroll;
            scrollbar-width: thin;

            .LongCheckListComponent {
                display: flex;
                flex-direction: column;
                gap: 18px;

                &-title {
                    display: flex;
                    flex-direction: column;
                    gap: 18px;

                    .mainTitle {
                        color: #1F384C;
                        font-family: Poppins;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 23px;
                        /* 143.75% */
                        letter-spacing: 0.5px;
                    }

                    .subTitle {
                        color: #1F384C;
                        font-family: Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 23px;
                        /* 164.286% */
                        letter-spacing: 0.5px;
                    }
                }

                &-body {
                    display: flex;
                    flex-direction: column;
                    width: fit-content;
                    gap: 6px;

                    &-majorMianType {
                        display: flex;
                        flex-direction: row;
                        width: fit-content;
                        padding: 4px 12px;
                        justify-content: start;
                        align-items: center;
                        gap: 10px;

                        .majorMianTypelabel {
                            &:hover {
                                text-decoration: underline;
                                cursor: pointer;
                            }
                        }

                        .countingBlock {
                            display: flex;
                            width: 16px;
                            height: 16px;
                            padding: 2px 2px;
                            justify-content: center;
                            align-items: center;
                            border-radius: 4px;
                            background: #AAA;
                            color: #FFF;
                            font-family: Poppins;
                            font-size: 12px;
                        }
                    }

                    &-majorsubType {
                        display: flex;
                        flex-direction: column;
                        gap: 6px;

                        &-title {
                            display: flex;
                            width: fit-content;
                            align-items: center;
                            gap: 9px;
                            color: $color-blue-mid;
                            margin-bottom: 12px;

                            &:hover {
                                text-decoration: underline;
                                cursor: pointer;
                            }

                            .countingBlock {
                                display: flex;
                                width: 16px;
                                height: 16px;
                                padding: 2px 2px;
                                justify-content: center;
                                align-items: center;
                                border-radius: 4px;
                                background: #AAA;
                                color: #FFF;
                                font-family: Poppins;
                                font-size: 12px;
                            }
                        }

                        &-body {
                            display: flex;
                            flex-direction: column;
                            gap: 8px;
                        }
                    }
                }
            }

            .SliderComponent {
                display: flex;
                flex-direction: column;
                gap: 18px;

                &-title {
                    display: flex;
                    flex-direction: column;
                    gap: 18px;

                    .mainTitle {
                        color: #1F384C;
                        font-family: Poppins;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 23px;
                        /* 143.75% */
                        letter-spacing: 0.5px;
                    }

                    .subTitle {
                        color: #1F384C;
                        font-family: Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 23px;
                        /* 164.286% */
                        letter-spacing: 0.5px;
                    }
                }


                &-body {

                    .slider-container {
                        display: flex;
                        flex-direction: column;
                        width: 100%;

                        .track {
                            height: 6px;
                            border-radius: 3px;
                            background: #ccc;
                            margin: 30px 12px;
                            position: relative;
                        }

                        .program-thumb {
                            height: 20px;
                            width: 20px;
                            border-radius: 50%;
                            background-color: $color-blue-mid;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            position: relative;

                            &-label {
                                position: absolute;
                                top: -25px;
                                /* Adjust this to fit the label better */
                                color: #000;
                                font-weight: bold;
                                font-size: 12px;
                                white-space: nowrap;
                            }
                        }
                    }
                }
            }
        }
    }
}

.ProgramGuideReport {
    display: flex;
    flex-direction: row;
    padding: 0 20px 0 0;
    gap: 20px;
    width: 100%;
    font-family: Poppins;


    .LeftPad {
        display: flex;
        flex-direction: column;
        gap: 52px;
        height: calc(100vh - 190px);
        overflow-y: scroll;
        scrollbar-width: thin;
        padding: 38px 25px;
        width: 30%;
        border-radius: 12px;
        background: #FFF;
        box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.13);

        .ReportOverview {
            display: flex;
            flex-direction: column;
            gap: 24px;

            label {
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 12px;
                /* 75% */
                letter-spacing: 0.5px;
            }

            .InputDetails {
                display: flex;
                height: 78px;
                padding: 26px 16px;
                justify-content: space-between;
                align-items: center;
                align-self: stretch;
                border-radius: 12px;


                &:hover {
                    background: #F0F6FF;
                    cursor: pointer;
                }

                span {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 12px;
                    /* 85.714% */
                    letter-spacing: 0.5px;
                }
            }
        }

        .MatchingOutcome {
            display: flex;
            flex-direction: column;
            gap: 32px;



            label {
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 12px;
                letter-spacing: 0.5px;
            }

            .SchoolDetails {
                display: flex;
                flex-direction: column;

                .ProgramCard {
                    display: flex;
                    flex-direction: row;
                    gap: 12px;
                    border-radius: 12px;
                    padding: 22px 12px 22px 16px;
                    justify-content: space-between;
                    align-items: center;

                    &:hover {
                        background: #F0F6FF;
                        cursor: pointer;
                    }

                    &-info {
                        display: flex;
                        flex-direction: row;
                        gap: 10px;

                        img {
                            width: 44px;
                            height: 43px;
                        }

                        span {
                            color: #1F384C;
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 12px;
                            letter-spacing: 0.5px;
                        }
                    }

                    &-score {
                        display: flex;
                        flex-direction: row;
                        justify-content: end;

                        span {
                            color: $color-blue-mid;
                            text-align: center;
                            font-family: Poppins;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 12px;
                            /* 50% */
                            letter-spacing: 0.5px;
                        }

                        #arrowIcon {
                            color: gray;
                        }
                    }
                }
            }
        }
    }

    .RightPad {
        display: flex;
        width: 69%;
        height: calc(100vh - 190px);
        overflow-y: scroll;
        scrollbar-width: thin;
        border-radius: 12px;
        background: #FFF;
        box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.13);
        padding: 33px 32px;
        align-items: flex-start;

        .InputDetailsCard {
            display: flex;
            width: 100%;
            flex-direction: column;
            gap: 39px;

            &-row {
                display: flex;
                flex-direction: row;
                width: 100%;
                gap: 88px;

                &-col {
                    display: flex;
                    flex-direction: column;
                    gap: 16px;

                    label {
                        color: var(--Icon_1, #AAA);
                        font-family: Poppins;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 12px;
                        /* 100% */
                        letter-spacing: 0.5px;
                        text-transform: uppercase;
                    }

                    span {
                        color: #000;
                        font-family: Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 12px;
                        /* 85.714% */
                        letter-spacing: 0.5px;
                    }

                    .cvFileCard {
                        display: flex;
                        flex-direction: row;
                        gap: 21px;
                        justify-content: start;
                        padding: 20px 30px 20px 20px;


                        box-shadow: 0px 2px 10px 0px rgba(170, 170, 170, 0.15);
                        border-radius: 12px;

                        .cvIconContainer {
                            display: flex;
                            width: 30px;
                            height: 30px;
                            padding: 3px 5px;
                            justify-content: center;
                            align-items: center;
                            border-radius: 5px;
                            background: #9CB8E3;
                            color: white;
                            font-size: 24px;
                        }

                        &-info {
                            display: flex;
                            flex-direction: column;
                            gap: 8px;

                            &-filename {
                                color: #7C7C7C;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 600;
                                letter-spacing: 0.5px;
                            }

                            &-fileDate {
                                color: var(--Icon_1, #AAA);

                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                letter-spacing: 0.5px;
                            }
                        }
                    }
                }
            }

            &-row2 {
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 16px;

                label {
                    color: var(--Icon_1, #AAA);
                    font-family: Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 12px;
                    /* 100% */
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                }

                .indicators {
                    display: flex;
                    flex-direction: column;
                    gap: 28px;
                    width: 100%;
                    height: fit-content;
                    padding: 24px 29px;
                    align-items: flex-start;
                    border-radius: 12px;
                    background: var(--Font_3, #F8F8F8);

                    .question {
                        display: flex;
                        flex-direction: column;
                        gap: 17px;

                        span {
                            color: #000;
                            font-family: Poppins;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 12px;
                            /* 85.714% */
                            letter-spacing: 0.5px;
                        }

                        .answers {
                            display: flex;
                            gap: 16px;
                            flex-wrap: wrap;

                            .Tag {
                                display: flex;
                                padding: 4px 12px;
                                justify-content: center;
                                align-items: center;
                                gap: 10px;
                                border-radius: 6px;
                                border: 1px solid var(--Border-1, #C8CBD9);
                                background: #FFF;

                                span {
                                    color: #000;
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: 20px;
                                    /* 142.857% */
                                    letter-spacing: 0.5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.cvForm {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &-title {
        color: $color-blue-mid;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: bold;
        line-height: 22px;
        /* 157.143% */
        letter-spacing: -0.098px;
    }

    &-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 8px;
        padding: 10px 10px;

        input {
            display: flex;
            min-height: 40px;
            padding: 8px 12px;
            align-items: center;
            gap: 12px;
            align-self: stretch;
            border-radius: 10px;
            border: 1px solid var(--Gray-30, #CBD5E1);
            background: #FAFAFA;
            color: var(--Gray-60, #475569);
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            /* 157.143% */
            letter-spacing: -0.098px;
        }


        .cvSelect {
            border-radius: 10px;
        }

        textarea {
            display: flex;
            min-height: 40px;
            padding: 8px 12px;
            align-items: center;
            gap: 12px;
            align-self: stretch;
            border-radius: 10px;
            border: 1px solid var(--Gray-30, #CBD5E1);
            background: #FAFAFA;
        }

        &-row1 {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 8px;

            .field-title {
                color: var(--Gray-80, #1E293B);
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                /* 166.667% */
                letter-spacing: -0.072px;

            }

            .cvFormSelect {
                width: 100%;
                border-radius: 10px;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
            }
        }

        &-row2 {
            display: flex;
            flex-direction: row;
            width: 100%;
            gap: 12px;

            &-item {
                display: flex;
                flex-direction: column;
                width: 50%;
                gap: 8px;
            }

            .field-title {
                color: var(--Gray-80, #1E293B);
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                /* 166.667% */
                letter-spacing: -0.072px;
            }

            .cvFormSelect2 {
                display: flex;
                min-height: 40px;
                padding: 8px 12px;
                align-items: center;
                gap: 12px;
                align-self: stretch;
                border-radius: 10px;
                border: 1px solid var(--Gray-30, #CBD5E1);
                background: #FAFAFA;
                color: var(--Gray-60, #475569);
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                /* 157.143% */
                letter-spacing: -0.098px;
            }
        }

        &-row3 {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;

            &-item {
                display: flex;
                flex-direction: column;
                width: 33%;
                gap: 8px;
            }

            .field-title {
                color: var(--Gray-80, #1E293B);
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20px;
                /* 166.667% */
                letter-spacing: -0.072px;

            }

            .cvFormSelect2 {
                display: flex;
                min-height: 40px;
                padding: 8px 12px;
                align-items: center;
                gap: 12px;
                align-self: stretch;
                border-radius: 10px;
                border: 1px solid var(--Gray-30, #CBD5E1);
                background: #FAFAFA;
                color: var(--Gray-60, #475569);
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                /* 157.143% */
                letter-spacing: -0.098px;
            }

        }

        .button-item {
            background-color: white;
            color: $color-blue-mid;
            width: fit-content;
            border: none;
            justify-content: start;
            font-family: Poppins;
            font-weight: bold;
            color: var(--Icon_1, #AAA);
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px;
            /* 142.857% */
            letter-spacing: -0.084px;

            &:hover {
                text-decoration: underline;
            }
        }

        .button-container {
            display: flex;
            flex-direction: column;
            gap: 8px;
            width: fit-content;
            align-items: start;

            .button-item {
                background-color: white;
                color: $color-blue-mid;
                border: none;
                justify-content: start;
                font-family: Poppins;
                font-weight: bold;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

    }


}

.reportprogramDetails {
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;


    &-headerRow {
        display: flex;
        flex: row;
        height: 68px;
        justify-content: space-between;
        align-items: start;
        width: 100%;


        &-left {
            display: flex;
            flex-direction: row;
            gap: 16px;

            img {
                border-radius: 8px;
                width: 68px;
                height: 68px;
            }

            .schoolname {
                display: flex;
                flex-direction: column;
                gap: 15px;

                &-label1 {
                    color: #1F384C;
                    font-family: Poppins;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 23px;
                    /* 127.778% */
                    letter-spacing: 0.5px;
                }

                &-label2 {
                    color: #7C7C7C;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 22px;
                    /* 137.5% */
                    letter-spacing: 0.5px;
                }
            }

        }

        &-right {
            display: flex;
            flex-direction: column;
            gap: 15px;
            align-items: center;

            .scorelabel1 {
                color: var(--Icon_1, #AAA);
                text-align: center;
                font-family: Poppins;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 12px;
                /* 100% */
                letter-spacing: 0.5px;
                text-transform: uppercase;
            }

            .scorelabel2 {
                color: var(--Main, #287EFF);
                text-align: center;
                font-family: Poppins;
                font-size: 40px;
                font-style: normal;
                font-weight: 500;
                line-height: 28px;
                /* 70% */
                letter-spacing: 0.5px;
            }
        }
    }

    &-overviewRow {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: fit-content;
        align-self: stretch;

        .overviewlabel1 {
            color: var(--Main, #287EFF);
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 12px;
            /* 100% */
            letter-spacing: 0.5px;
            text-transform: uppercase;
        }

        &-left {
            display: flex;
            padding: 24px;
            flex-direction: column;
            width: 49%;

            align-items: flex-start;
            gap: 22px;
            border-radius: 12px;
            background: #F8F8F8;


            .overviewDetails {
                max-height: 300px;
                overflow-y: scroll;
                scrollbar-width: thin;
                color: #000;
                font-family: Poppins;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                /* 142.857% */
                letter-spacing: 0.5px;
            }
        }

        &-right {
            display: flex;
            padding: 24px;
            flex-direction: column;
            width: 49%;
            align-items: flex-start;
            gap: 10px;
            border-radius: 12px;
            background: #F8F8F8;
        }
    }

    &-DetailsRow {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
        gap: 32px;
        padding: 18px 27px 39px 27px;

        .score-details {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 25px;

            .score-nav {
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: space-between;
                padding-bottom: 0;
                /* No padding to align borders */
                border-bottom: 2px solid #AAA;
                /* Gray border for the entire nav bar */

                .score-nav-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    cursor: pointer;
                    padding: 9px 0;
                    transition: all 0.3s ease;

                    /* Default transparent border for non-active items */
                    border-bottom: 2px solid transparent;
                    /* Inactive items don't show underline */

                    .score-nav-item-title {
                        color: #AAA;
                        font-family: 'Poppins', sans-serif;
                        font-size: 12px;
                        font-weight: 500;
                        text-transform: uppercase;
                        opacity: 0.6;
                        letter-spacing: 0.5px;
                    }

                    /* Active state is now applied to the div */
                    &.active {
                        .score-nav-item-title {
                            color: #287EFF;
                            /* Blue color for the active item */
                            opacity: 1;
                            /* Fully opaque for active */
                        }

                        border-bottom: 2px solid #287EFF;
                        /* Blue underline for the active item */
                        margin-bottom: -2px;
                        /* Align the blue underline with the gray nav bar border */
                    }
                }
            }

            .score-content {
                display: flex;
                flex-direction: column;

                p {
                    align-self: stretch;
                    color: #000;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    /* 125% */
                    letter-spacing: 0.5px;
                }
            }

        }
    }
}