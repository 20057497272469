.CvQuickStart {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-bottom: 30px;
    border-bottom: 0.5px solid #C8CBD9;

    &-label {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 500;
        line-height: 23px;
        letter-spacing: 0.5px;
        text-align: left;
    }

    &-contents {
        display: flex;
        flex-direction: row;
        gap: 28px;
        justify-content: start;

        @media screen and (max-width:1044px) {
            flex-direction: column;


        }

        &-container {
            width: 341px;
            height: 300px;
            background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23333' stroke-width='3' stroke-dasharray='6%2c9' stroke-dashoffset='5' stroke-linecap='square'/%3e%3c/svg%3e");
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: center;
            justify-content: center;

            @media screen and (max-width: $breakpoint-small) {
                width: 180px;
                height: 158px;
                gap: 3px;


            }

            &:hover {
                cursor: pointer;
                background-color: $color-blue-light;

            }

            &.dragging {
                background-color: $color-blue-light;
                cursor: copy;
            }

            img {
                width: 143px;
                height: 150px;

                @media screen and (max-width: $breakpoint-small) {
                    width: 75.5px;
                    height: 79px;
                }
            }

            label {
                font-family: Poppins;
                font-size: 18px;
                font-weight: 600;
                line-height: 22px;
                letter-spacing: 0.5px;
                text-align: center;

                @media screen and (max-width: $breakpoint-small) {
                    font-size: 14px;
                }
            }

            p {
                font-family: Poppins;
                font-size: 12px;
                font-weight: 400;
                line-height: 12px;
                text-align: center;
                color: $color-gray;
                margin-bottom: 0;
            }
        }
    }
}

.CvPrevFile {
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 30px;
    gap: 30px;

    &-label {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 500;
        line-height: 23px;
        letter-spacing: 0.5px;
        text-align: left;
    }

    &-container {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        gap: 28px;
    }


    &-fileCard {
        position: relative;

        &-container {
            padding: 25px 21px;
            min-width: 248px;
            min-height: 160px;
            border: 1px 0px 0px 0px;
            border: 1px solid #EEEEEE;
            box-shadow: 0px 4px 10px 0px #AAAAAA40;
            border-radius: 12px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @media screen and (max-width: $breakpoint-small) {
                width: 200px;
                height: fit-content;
            }

            .fileCard-row1 {
                display: flex;
                width: 100%;
                justify-content: space-between;

                .fileCard-CvIconContainer {
                    width: 30px;
                    height: 30px;
                    background-color: #9CB8E3;
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    #cvIcon {
                        width: 24px;
                        height: 24px;
                        color: white;
                    }
                }

                .fileCard-StarIconContainer {
                    :hover {
                        cursor: pointer;
                    }

                    #starIcon {
                        width: 24px;
                        height: 24px;
                        color: $color-blue-mid;
                    }
                }
            }

            .fileCard-row2 {
                margin-top: 15px;

                .fileCard-filename {
                    width: 100%;
                    font-family: Poppins;
                    font-size: 16px;
                    font-weight: 600;
                    letter-spacing: 0.5px;
                    text-align: left;
                    display: flex;
                    word-wrap: break-word;
                    word-break: break-all;

                    &:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }

                .fileCard-fileDate {
                    font-family: Poppins;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 22px;
                    letter-spacing: 0.5px;
                    text-align: left;
                    color: $color-gray;
                }
            }

            .fileCard-row3 {
                display: flex;
                flex-direction: column;
                width: 100%;
                justify-content: end;
                align-items: end;


                :hover {
                    cursor: pointer;
                }

                #optionIcon {
                    width: 24px;
                    height: 24px;
                    color: $color-gray;
                }
            }


        }

        .dropDown-list {
            position: absolute;
            top: 100%;
            right: 0;
            transform: translateY(-15px);
            width: fit-content;
            display: flex;
            padding: 3px;
            flex-direction: column;
            align-items: flex-end;
            gap: 10px;
            border-radius: 10px;
            opacity: 0.95;
            background: #FFF;
            box-shadow: 0px 0px 10px 0px rgba(170, 170, 170, 0.50);
            z-index: 1;

            .dropDown-item {
                display: flex;
                width: 100%;
                padding: 5px 10px;
                justify-content: center;
                align-items: center;
                gap: 3px;

                &:hover {
                    background-color: #F0F6FF;
                    color: #287EFF;
                    cursor: pointer;
                }

                span {
                    text-align: center;
                    font-family: Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 12px;
                    /* 100% */
                    letter-spacing: 0.5px;
                }
            }
        }
    }


}

.RenamePopup {
    position: absolute;
    top: 0;
    left: min(280px, 30%);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: calc(100% - min(280px, 30%));
    background: rgba(0, 0, 0, 0.5);
    z-index: 4;


    &-window {
        display: flex;
        width: 400px;
        padding: 30px 40px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 25px;
        border-radius: 10px;
        background: #FFF;
        opacity: 1;

        label {
            color: #1F384C;
            text-align: center;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 23px;
            /* 127.778% */
            letter-spacing: 0.5px;
        }

        .rename-input {
            display: flex;
            padding: var(--spacing-2-5, 10px) 20px;
            align-items: flex-start;
            gap: var(--spacing-2-5, 10px);
            align-self: stretch;
            border-radius: 8px;
            background: var(--Font_3, #F8F8F8);
            border-color: transparent;

            &:focus {
                outline: none;
            }
        }

        .rename-buttons {
            display: flex;
            width: 100%;
            justify-content: space-between;

            .button-item {
                display: flex;
                padding: 5px 16px;
                justify-content: center;
                align-items: center;
                gap: 8px;
                border-radius: 10px;
                border: 1px solid var(--Gray-30, #CBD5E1);

                &:hover {
                    color: white;
                    background: #287EFF;
                }
            }
        }
    }
}