.codeRain-Container {
    @extend .d-y;
    width: 98%;
    height: 98vw;
    overflow: hidden;
    position: absolute;
    top: 0;    
    user-select: none;

    .codeRain {
        position: absolute;
        z-index: 0;
        @extend .font2;
        color: transparent;
        text-wrap: nowrap;
        background-size: 30%;
        background-repeat: no-repeat;
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.06) 30%, rgba(0, 0, 0, 0.1) 70%, rgba(0, 0, 0, 0) 100%);
        background-clip: text;
        top: 100px;
        left: 0px;
        animation: x-scroll-rain 2s infinite linear forwards;
    }

    .codeRain-2 {
        @extend .codeRain;
        top: 580px;
        animation: x-scroll-rain 3s infinite linear forwards;
    }

    .codeRain-reverse {
        @extend .codeRain;
        animation: x-scroll-rain 1s infinite linear reverse forwards 1s;
        top: 300px;

    }


    @keyframes x-scroll-rain {
        0% {

            background-position-x: 0%;
        }

        100% {


            background-position-x: 100%;

        }
    }
}