.dashboard {

    height: 100vh;
    width: 100vw;
    gap: 1%;
    display: flex;


    .navBar {
        width: min(280px, 30%);
        height: 100%;
        min-width: 90px;
        padding: min(2%, 36px) min(1%, 24px);
        display: flex;
        justify-content: center;
        background-color: $color-white;
        transition: .3s ease;

        @media screen and (max-width:$breakpoint-small) {
            width: 90px;

            &:hover {
                min-width: 280px;

                .text,
                .submitText {
                    display: block !important;
                }
            }
        }


        .nav-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow: scroll;
            scrollbar-width: none;
            gap: 5%;
            color: $color-gray;
            width: 100%;


            .head {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                @extend .underline-gray;

                .brand {
                    width: max(72%, 160px);
                    display: flex;
                    justify-content: center;

                    .brand-icon {
                        max-width: max(20%, 48px);
                    }
                }

                .icon {
                    width: 20%;
                    font-size: 28px;
                    text-align: right;
                    @extend .R-hidden;
                    display: none;

                    &:hover {
                        color: black;
                    }
                }

            }

            .body {
                width: 100%;

                .core {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;

                }
            }

            .foot {
                margin-top: auto;
                width: 100%;
                bottom: 0;
            }

            .navItem {
                height: 100%;
                position: relative;
                cursor: pointer;
                z-index: 10;

                @mixin navItemHovered {
                    background-color: $color-blue-mid;
                    color: white;
                    box-shadow: 0px 4px 4px 0px rgba(170, 170, 170, 0.25);
                    transform: translateY(-4px);
                    transition: .5s ease;

                }

                //一级菜单
                .mainItem {
                    position: relative;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    padding: 4px 16px;
                    border-radius: 10px;
                    margin-top: 0px;
                    margin-bottom: 0px;

                    &:hover {
                        @include navItemHovered();
                    }

                    .icon {
                        font-size: 24px;

                        transition: .3 ease;
                        height: 100%;

                        @media screen and (max-width:$breakpoint-small) {
                            font-size: 32px;
                        }
                    }

                    .text {

                        font-size: 14px;
                        line-height: 12px;
                        letter-spacing: 4px;
                        @extend .R-hidden;
                    }

                    .arrow {
                        position: absolute;
                        right: min(6px, 1%);
                        top: 0;
                        height: 100%;
                        display: none;
                        align-items: center;
                        font-size: 20px;
                        transition: .3s ease;
                        transform: rotate(0deg);
                    }

                }

                //是否有子级
                &:has(.subsItem >*) {
                    .arrow {
                        display: flex;
                    }

                    //有子级且切换为真
                    &:has(.navItem-expand) {
                        background-color: $color-blue-light;
                        border: 1px solid $color-blue-mid;
                        border-radius: 10px;

                        .mainItem {
                            @include navItemHovered();
                        }

                        .arrow {
                            transform: rotate(-90deg);

                        }

                        .subsItem {
                            max-height: 160px;
                            pointer-events: all;
                        }
                    }
                }

                //是否选中
                &:not(.subsItem >*) {

                    &:has(.choosed) {
                        color: $color-blue-mid;
                    }
                }

                //二级菜单
                .subsItem {
                    cursor: default;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    font-size: 14px;
                    overflow: hidden;
                    transition: .3s ease;
                    max-height: 0px;
                    padding: 4px 8px;
                    gap: 4px;
                    pointer-events: none;


                    .submitText {
                        @extend .R-hidden;

                    }



                    //子级菜单中各项样式
                    >* {

                        padding: 5px 20px;
                        display: flex;
                        justify-content: start;
                        align-items: center;
                        width: 100%;
                        gap: 8px;
                        border-radius: 10px;
                        color: $color-gray;




                        :first-child {
                            font-size: 24px;
                        }
                    }

                    .choosed {
                        color: $color-blue-mid;
                    }

                    >* {

                        //自己菜单鼠标悬浮
                        &:hover {
                            transition: .3s ease;
                            background-color: $color-blue-mid;
                            color: $color-white;
                            border-radius: 10px;
                        }
                    }

                }


            }

        }
    }

    .mainPage {
        width: 80%;

        gap: min(1%, 10px);
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 28px;
        padding-bottom: 20px;

        .router {
            width: 100%;
            padding: 0px 1%;

            .contentRouter {
                @extend .font6;
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: space-between;
                color: $color-gray;
                height: 100%;
                width: 100%;

                .content {
                    padding-bottom: 12px;
                }

                .routeText {
                    display: flex;
                    flex-direction: row;
                    gap: 16px;

                    .routeUnit {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 4px;
                        cursor: pointer;

                       

                        .icon {
                            font-size: 32px;
                        }
                    }

                    //分割线
                    &>:nth-child(n+2) {
                        &::before {
                            content: "/";
                            width: 20px;

                        }
                    }
                }
            }
        }

        >.content {
            width: 100%;
            height: 100%;
            padding: .5% 0 0 1%;
            overflow: scroll;
            scrollbar-width: none;
        }
    }



}

.surveyFirst {
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: relative;
    letter-spacing: 4px;

    .brand {
        margin-left: 8%;
        margin-top: 3%;
        margin-bottom: 5%;
    }

    .main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 80px;

        .title {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            gap: 20px;

            .indexTitle {
                @extend .font3;
                color: $color-gray;
            }

            .text {
                @extend .font2;
            }
        }

        .content {
            .container {
                .surveyUnit1 {
                    .unitGroup {
                        display: flex;
                        flex-direction: row;
                        gap: 40px;
                        flex-wrap: wrap;
                        justify-content: center;

                        >* {
                            width: 240px;
                        }
                    }
                }
            }
        }

        .btnGroup {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            text-wrap: nowrap;
            gap: 80px;
            flex-wrap: wrap;

            .prevBtn {
                @extend .button-stepLeft;


            }

            .nextBtn {
                @extend .button-stepRight;

            }
        }
    }

}