@import '../../styles/base/Variables';
@import '../../styles/base/Font';

//主页内容容器
.content-home {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  max-width: 1200px; // 添加最大宽度
  margin: 0 auto; // 居中内容
  padding: 0 20px; // 添加左右内边距
  box-sizing: border-box; // 确保padding不会增加整体宽度

  ::-webkit-scrollbar {
    display: none;
    /* Chrome Safari */
  }

  .welcome-banner {
    background-color: $color-blue-light;
    padding: 4%;
    border-radius: 1vw;
    box-shadow: 0 0.3vw 0.5vw rgba(0, 0, 0, 0.1);
    margin-bottom: 3vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-top: 30px;

    .welcome-text {
      flex: 1;
      max-width: 60%; // 限制文本区域宽度

      h1 {
        @extend .font3;
        margin: 0;
        color: $color-dark;

        .username {
          color: $color-blue-mid;
        }
      }

      p {
        @extend .font5;
        color: $color-mid;
        margin: 1vw 0 0 0;
        text-align: left;
      }

      .usage {
        @extend .font5;
        color: $color-semilight;
        margin-top: 1vw;
        white-space: nowrap;
        text-align: left;
      }
    }

    .welcome-image {
      width: 40%; // 使用相对宽度
      max-width: 335px;
      min-height: 224px;
      background-size: cover;
      background-position: center;
      border-radius: 8px;
      margin-left: 20px;
      position: absolute;
      right: 40px;
      z-index: 1;
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    }
  }

  // 主要内容区域
  .main-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
  }

  // 最近文件和应用服务共享样式
  .recent-files,
  .application-services,
  .daily-articles {
    margin-bottom: 30px;

    .files-header,
    .services-header,
    .articles-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;

      h2 {
        @extend .font4;
        font-weight: bold;
        color: $color-dark;
      }

      span {
        @extend .font5;
        color: $color-mid;
        cursor: pointer;
      }
    }

    .files-grid,
    .services-grid,
    .articles-grid {
      display: grid;
      
      gap: 12px;
    }

    .files-grid {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
   
    }

    .services-grid,
    .articles-grid {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

    }

    .service-card,
    .article-card {
      border: 1px solid #ddd;
      padding: 18px;
      border-radius: 8px;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      text-align: left;
      align-items: flex-start;
      cursor: pointer;

      h2,
      h3 {
        @extend .font4;
        font-weight: bold;
        width: 100%;
        color: $color-dark;
        text-align: left;
        margin-bottom: 8px;
      }

      p {
        @extend .font5;
        color: $color-dark;
        width: 100%;
        text-align: left;
        margin-bottom: 8px;
      }
    }

    //文件卡片
    .file-card {
      position: relative;
      padding: 25px 21px;
      width: 100%;
      max-width: 300px;
      min-height: 160px;
      border: 1px solid #EEEEEE;
      box-shadow: 0px 4px 10px 0px #AAAAAA40;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media screen and (max-width: $breakpoint-small) {
        height: auto;
        min-height: 160px;
      }

      .file-icon {
        width: 30px;
        height: 30px;
        background-color: #9CB8E3;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 24px;
      }

      .star-icon {
        position: absolute;
        top: 25px;
        right: 21px;
        cursor: pointer;

        svg {
          width: 24px;
          height: 24px;
          color: $color-blue-mid;
        }
      }

      .file-info {
        margin-top: 15px;

        h2 {
          font-family: Poppins;
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 0.5px;
          text-align: left;
          margin-bottom: 5px;
          word-wrap: break-word;
          word-break: break-all;

          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }

        p {
          font-family: Poppins;
          font-size: 12px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0.5px;
          text-align: left;
          color: $color-gray;
        }
      }

      .options-icon {
        position: absolute;
        bottom: 25px;
        right: 21px;
        cursor: pointer;

        svg {
          width: 24px;
          height: 24px;
          color: $color-gray;
        }
      }
    }

    //服务卡片
    .service-card {
      width: 100%;
      height: 100%;
      border: 1px solid #ddd;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
      position: relative;

      .image-placeholder {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        z-index: 1;
      }

      .text-container {
        position: relative;
        z-index: 2;
        padding: 16px;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 8px;
        margin: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h3 {
          font-size: 18px;
          margin: 0 0 8px 0;
        }

        p {
          font-size: 10px;
          margin: 0 0 16px 0;
          flex: 1;
        }

        button {
          width: 100px;
          height: 32px;
          background-color: #007bff;
          color: white;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          align-self: flex-start;
        }
      }
    }

    .article-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      border: 1px solid #ddd;
      border-radius: 8px;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      width: 100%;

      .article-image {
        width: 100%;
        height: 150px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .article-text {
        padding: 12px;

        h3 {
          font-size: 12px;
          font-weight: bold;
          margin: 8px 0 4px 0;
          color: $color-dark;
        }

        p {
          font-size: 12px;
          color: $color-mid;
          margin: 0;
        }
      }
    }
  }
}

.quota-display {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 15px;
  font-family: poppins;

  .quota-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

    h2 {
      @extend .font4;
      font-weight: bold;
      color: $color-dark;
    }
  }

  .quota-content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    padding: 0 10px;
    margin-bottom: 15px;

    .quota-card {
      width: 24%;
      /* Four items per row by default */
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
      margin-bottom: 15px;
      /* Add spacing between rows */

      @media (max-width: 992px) {
        width: 32%;
        /* Three items per row for medium screens */
      }

      @media (max-width: 768px) {
        width: 48%;
        /* Two items per row for smaller screens */
      }

      @media (max-width: 480px) {
        width: 100%;
        /* Full width for very small screens */
      }
    }
  }
}

@media (max-width: 768px) {
  .content-home {
    padding: 0 10px;

    .welcome-banner {
      flex-direction: column;
      align-items: flex-start;

      .welcome-text {
        max-width: 100%;
      }

      .welcome-image {
        position: relative;
        right: auto;
        width: 100%;
        max-width: 100%;
        margin-left: 0;
        margin-top: 20px;
        box-shadow: none;
      }
    }

    .recent-files,
    .application-services,
    .daily-articles {

      .files-grid,
      .services-grid,
      .articles-grid {
        grid-template-columns: 1fr;
      }

      .file-card,
      .service-card,
      .article-card {
        max-width: 100%;
      }
    }
  }
}