.CVEditorPageContainer {
  display: flex;
  direction: row;
  width: 100%;
  height: 100%;
  padding: 0;

}

.cvRightbar {
  height: calc(100vh - 200px);
  overflow-y: scroll;
  scrollbar-width: none;
  overflow-x: hidden;

  &-top {
    display: flex;
    flex-direction: row;
    width: fit-content;
    padding: 24px 0px 0 0;


    .rightbarIcon-container {
      height: 100%;
      width: 54px;
      display: flex;
      justify-content: center;
      padding-top: 3px;

      #sidebarbutton {
        width: 18px;
        height: 18px;
        color: $color-mid;

        &:hover {
          color: $color-blue-mid;
          cursor: pointer;
        }
      }

    }

    .rightbarForm-container {
      display: flex;
      flex-direction: column;
      justify-content: start;
      width: 356px;
      padding-right: 30px;

      .rightbarForm-header {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 15px;
        margin-bottom: 44px;

        label {
          font-family: 'Poppins';
          font-size: 18px;
          line-height: 24px;
          letter-spacing: -0.8%;
          text-align: left;
          font-weight: bolder;
        }

        .formchooser {
          display: flex;
          flex-direction: row;
          justify-content: start;
          gap: 8%;
          border-bottom: 1px solid #E2E8F0;

          &-item {
            display: flex;
            gap: 5px;
            padding: 5px 3px;
            justify-content: center;
            align-items: center;
            border-bottom: 1px solid transparent;

            #chooserIcon {
              height: 20px;
              width: 20px;
              color: $color-mid;
            }

            span {
              font-family: 'Poppins';
              font-size: 12px;
              line-height: 12px;
              letter-spacing: .5px;
              font-weight: 600;
              color: $color-mid;
            }

            &:hover {
              border-bottom: 1px solid $color-blue-mid;
              color: $color-blue-mid;
              cursor: pointer;

              #chooserIcon {
                color: $color-blue-mid;
                /* Change icon color on hover */
              }

              span {
                color: $color-blue-mid;
                /* Change text color on hover */
              }
            }

            &.is-active {
              border-bottom: 1px solid $color-blue-mid;
              cursor: pointer;

              #chooserIcon {
                color: $color-blue-mid;
              }

              span {
                color: $color-blue-mid;
              }
            }
          }
        }
      }

      .SubForm {
        display: flex;
        flex-direction: column;

        &-question {
          font-family: Poppins;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: -0.6%;
          text-align: left;
          margin-bottom: 8px;
        }

        &-text {
          display: flex;
          min-height: 40px;
          padding: 8px 12px;
          align-items: center;
          gap: 12px;
          align-self: stretch;
          border-radius: 10px;
          border: 1px solid var(--Gray-30, #CBD5E1);
          background: var(--gray-0-white, #FFF);
          margin-bottom: 20px;
        }

        .SubForm-textarea {
          display: flex;
          padding: 12px;
          min-height: 108px;
          flex-direction: column;
          gap: var(--spacing-2-5, 10px);
          border-radius: 10px 10px 10px 10px;
          border: 1px solid var(--Gray-30, #CBD5E1);
          background: var(--gray-0-white, #FFF);

          &:focus {
            outline: none;
          }
        }

        &-textareaContainer {
          display: flex;
          flex-direction: column;
          gap: 0px;

          .SubForm-textarea {
            display: flex;
            padding: 12px;
            min-height: 108px;
            flex-direction: column;
            gap: var(--spacing-2-5, 10px);
            border-radius: 10px 10px 0px 0px;
            border: 1px solid var(--Gray-30, #CBD5E1);
            border-bottom: none;
            background: var(--gray-0-white, #FFF);
            resize: none;

            &:focus {
              outline: none;
            }
          }

          .SubForm-selectContainer {
            border: 1px solid var(--Gray-30, #CBD5E1);
            border-top: none;
            border-radius: 0px 0px 10px 10px;

            .SubForm-select {
              padding: 3px 10px;
              width: fit-content;
              border: none;
              border-radius: 0px 0px 10px 10px;

              &:focus {
                outline: none;
              }
            }
          }

        }

        &-buttonContainer {
          display: flex;
          flex-direction: column;
          gap: 8px;
          padding: 24px 0px;

          .SubForm-button {
            display: flex;
            padding: var(--spacing-2-5, 10px) 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            border: 2px solid transparent;
            border-radius: 10px;
            background: var(--Main, #287EFF);
            color: var(--gray-0-white, #FFF);

            /* Text sm/Bold */
            font-family: 'Poppins';
            font-size: 14px;
            font-style: normal;
            font-weight: bold;
            line-height: 20px;
            /* 142.857% */
            letter-spacing: -0.084px;

            &:hover {
              border: 2px solid $color-blue-dark;
              //font-weight: bold;
            }

            #buttonIcon {
              height: 20px;
              width: 20px;
            }
          }

          .SubForm-button2 {
            display: flex;
            padding: var(--spacing-2-5, 10px) 16px;
            justify-content: center;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            border: 2px solid transparent;
            border-radius: 10px;


            /* Text sm/Bold */
            font-family: 'Poppins';
            font-size: 14px;
            font-style: normal;
            font-weight: bold;
            line-height: 20px;
            /* 142.857% */
            letter-spacing: -0.084px;

            &:hover {
              border: 2px solid $color-blue-dark;
              //font-weight: bold;
            }

            #buttonIcon {
              height: 20px;
              width: 20px;
            }
          }
        }
      }
    }
  }

  &-bottom {
    width: 410px;
    padding: 24px 30px 20px 54px;
    border-top: 1px solid #E2E8F0;

    .CvResultCard {
      display: flex;
      flex-direction: column;
      gap: 20px;

      &-label {
        color: var(--Main, #287EFF);
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        /* 125% */
        letter-spacing: -0.096px;
      }

      &-textarea {
        display: flex;
        padding: 12px;
        min-height: 108px;
        flex-direction: column;
        gap: var(--spacing-2-5, 10px);
        border-radius: 10px;
        border: 1px solid var(--Gray-30, #CBD5E1);
        background: var(--gray-0-white, #FFF);
        margin-bottom: 20px;
      }

      &-button {
        display: flex;
        padding: var(--spacing-2-5, 10px) 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        border: 2px solid transparent;
        border-radius: 10px;
        background: var(--Main, #287EFF);
        color: var(--gray-0-white, #FFF);

        /* Text sm/Bold */
        font-family: 'Plus Jakarta Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: bold;
        line-height: 20px;
        /* 142.857% */
        letter-spacing: -0.084px;

        &:hover {
          border: 2px solid $color-blue-dark;
          //font-weight: bold;
        }
      }

    }
  }

}

.cveditor-container {
  position: relative;

  .tiptap {
    padding: 20px;
    border-radius: 10px;
    min-height: 29.7cm;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    font-family: "Times New Roman", Times, serif;

    // Headings
    h3 {
      height: fit-content;
      margin: 0;
      display: flex;
      align-items: center;
    }

    h6 {
      height: fit-content;
      display: flex;
      justify-content: space-between;
      margin: 0;
      font-size: 18px;
    }

    // Paragraphs
    p {
      height: fit-content;
      text-align: left;
      margin: 0;
      font-size: 16px;
    }

    // Horizontal Rules
    hr {
      border-bottom: 1px solid #ccc;
      margin: 0 0 4px 0;
    }

    // Unordered Lists
    ul {
      padding-left: 20px;
      list-style: disc;
    }

    span {
      height: fit-content;
      margin: 0;
      display: flex;
      align-items: center;
    }

    // Focus State
    ul.has-focus {
      border-radius: 5px;
      box-shadow: 0 0 0 1px rgb(40, 126, 255);
    }

    .paragraphhas-focus {
      border-radius: 5px;
      box-shadow: 0 0 0 1px rgb(40, 126, 255);
    }

    .addButtonContainer {
      padding: 0px 0px;
      margin-bottom: 10px;

      button {
        margin-left: 0;
        background-color: transparent;
        border: none;
        font-size: 18px;
        font-weight: 600;
        color: $color-blue-mid;
      }
    }

    .DeleteSectionButton {
      display: flex;
      width: fit-content;
      height: fit-content;
      margin-bottom: 0;

      align-items: center;
      justify-content: center;

      button {
        display: flex;
        background-color: transparent;
        border: none;
        font-size: 20px;
        font-weight: 600;
        padding: 0 0;
        color: $color-mid;
        align-items: center;
        justify-content: center;

        &:hover {
          color: red;
        }
      }
    }

    .DeleteItemButton {
      display: flex;
      width: fit-content;
      height: fit-content;
      margin-bottom: 0;

      align-items: center;
      justify-content: center;

      button {
        display: flex;
        background-color: transparent;
        border: none;
        font-size: 16px;
        font-weight: 600;
        padding: 0 0;
        color: $color-mid;
        align-items: center;
        justify-content: center;

        &:hover {
          color: red;
        }
      }
    }
  }

  // Editor Menubar Container
  .editor-menubarContainer {
    position: sticky;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    top: 0;
    background-color: #ffffff;
    padding: 10px 0;
    z-index: 2;
    border-bottom: 2px solid #F3F3F3;

    // Control Group
    .control-group {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: left;

      //margin: 10px 0; // Commented out as it is not in use
      // Button Group
      .button-group {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        justify-content: center;
        align-items: center;

        button {
          padding: 5px 5px;
          border: 1px solid #ffffff;
          background: #ffffff;
          cursor: pointer;
          border-radius: 4px;

          &.is-active {
            background: #ddd;
            font-weight: bold;
          }

          #editor-icon {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    .editor-downloadButton {
      height: fit-content;
      font-size: 16px;
      border: none;
      background-color: #fff;
      margin: 0 -10px;
    }

    .editor-saveButton {
      display: inline-flex;
      width: fit-content;
      height: fit-content;
      padding: var(--spacing-2-5, 6px) 16px;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      border-color: transparent;
      background: var(--Main, #287EFF);
      color: var(--gray-0-white, #FFF);

      /* Text sm/Bold */
      font-family: "Poppins";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      /* 142.857% */
      letter-spacing: -0.084px;
      white-space: nowrap;
      /* Prevent the button text from breaking into multiple lines */

      &:hover {
        text-decoration: underline;
      }
    }

    .editor-characterCount {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      white-space: nowrap;
    }
  }

  // Editor Content Container
  .editor-content-container {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 20px;

    // Content Container
    .content-container {
      box-shadow: 0px 0px 10px 0px #AAAAAA;
      padding: 0px;
      border-radius: 10px;
      width: 21cm;
      min-height: 29.7cm;
    }

  }


  .floating-menu {
    position: absolute;
    display: flex;
    width: fit-content;
    flex-direction: row;
    gap: 3px;
    border-radius: 10px;
    padding: 3px 3px;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px 0px #AAAAAA;
    opacity: 95%;
    z-index: 1;

    &-translator {
      width: 70px;
      height: 37px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 10px 0 0 10px;



      span {
        font-family: Poppins;
        font-size: 9px;
        letter-spacing: .5px;
        font-weight: 600;
      }

      &:hover {
        background-color: rgb(240, 246, 255);
        cursor: pointer;
        color: rgb(40, 126, 255);
      }

    }

    &-rewrite {
      width: 70px;
      height: 37px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: rgb(240, 246, 255);
        cursor: pointer;
        color: rgb(40, 126, 255);
      }

      span {
        font-family: Poppins;
        font-size: 9px;
        letter-spacing: .5px;
        font-weight: 600;

      }
    }

    &-generator {
      width: 70px;
      height: 37px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 10px 0 0 10px;

      &:hover {
        background-color: rgb(240, 246, 255);
        cursor: pointer;
        color: rgb(40, 126, 255);
      }

      span {
        font-family: Poppins;
        font-size: 9px;
        letter-spacing: .5px;
        font-weight: 600;
      }
    }

  }
}