.x-scroll {
    &-3p {
        overflow: hidden;

        & &-container {
            display: flex;
            flex-direction: row;
            justify-content: start;
            animation: xscroll-3 16s forwards ease infinite normal;

        }
    }

    &-4p {
        overflow: hidden;
        width: 100%;
        flex-grow: 1;

        & &-container {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: start;
            animation: xscroll-4 16s forwards ease infinite normal;

            div {
                width: 100%;
            }
        }
    }

    &-continous {

        overflow-x: hidden;
        // overflow-y: visible;
        padding: 2% 0;



        .scroll-container {
            display: flex;
            flex-direction: row;
            justify-content: start;
            animation: xscroll-continous-3 20s forwards linear infinite normal;

            &:hover {
                animation-play-state: paused;
            }
        }

        .scroll-container-reverse {
            display: flex;
            flex-direction: row;
            justify-content: start;
            animation: xscroll-continous-3 20s forwards linear infinite reverse;

            &:hover {
                animation-play-state: paused;
            }
        }
    }


}

@mixin xscroll-continous-($num) {
    @keyframes xscroll-continous-#{$num} {
        0% {
            transform: translate(0%, 0%);
        }

        100% {
            transform: translate(-50%, 0%);
        }
    }
}

@include xscroll-continous-(3);
@include xscroll-continous-(4);
@include xscroll-continous-(5);

@keyframes xscroll-3 {
    0% {
        transform: translate(0%, 0%);
    }

    5% {
        transform: translate(-100%, 0%);
    }

    33% {
        transform: translate(-100%, 0%);
    }

    38% {
        transform: translate(-200%, 0%);
    }

    66% {
        transform: translate(-200%, 0%);
    }

    71% {
        transform: translate(-300%, 0%);
    }

    100% {
        transform: translate(-300%, 0%);
    }


}

@keyframes xscroll-4 {
    0% {
        transform: translate(0%, 0%);
    }

    3% {
        transform: translate(-100%, 0%);
    }

    25% {
        transform: translate(-100%, 0%);
    }

    28% {
        transform: translate(-200%, 0%);
    }

    50% {
        transform: translate(-200%, 0%);
    }

    53% {
        transform: translate(-300%, 0%);
    }

    75% {
        transform: translate(-300%, 0%);
    }

    78% {
        transform: translate(-400%, 0%);
    }

    100% {
        transform: translate(-400%, 0%);
    }


}