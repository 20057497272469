.baseContent {
    display: flex;
    flex-direction: column;
    padding-right: 3%;
    gap: 16px;

    >* {
        @extend .baseUnit;
    }

    .title {
        @extend .font6;
    }
}

.baseUnit {
    display: flex;
    flex-direction: column;
    gap: 28px;
}

.baseItem {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.baseGap {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

//标准虚线框卡片
.boxCard {
    &-base {
        // border: 1px dashed;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23333' stroke-width='3' stroke-dasharray='6%2c9' stroke-dashoffset='5' stroke-linecap='square'/%3e%3c/svg%3e");
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;

        &:hover {
            cursor: pointer;
            background-color: $color-blue-light;

        }

        .img {
            display: flex;
            justify-content: center;
        }

        .txt {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            gap: 16px;

            .title {}

            .tips {
                color: $color-gray;
                @extend .font5;
                width: 80%;

            }

            .link {}
        }
    }

    &-dragDrop {}

    &-function {
        position: relative;
        .inputFile {
            width: 100%;
            height: 100%;
            position: absolute;
            >*{
                width: 100%;
                height: 100%;
                opacity: 0;
            }
        }
    }
}

.fileCard {
    position: relative;
    &-base {
        border: 1px solid rgba(238, 238, 238, 1);
        box-shadow: 0px 4px 10px 0px #AAAAAA40;
        border-radius: 12px;
        padding: 24px 28px;
        display: flex;
        width: fit-content;
        min-height: 160px;
        flex-direction: column;
        gap: 16px;

        .icon {
            >* {
                color: $color-gray-dark;
            }

            >*:first-child {
                background-color: rgb(192, 217, 191);
                padding: 2px 0;
                border-radius: 4px;
                color: $color-white;
            }

            display: flex;
            justify-content: space-between;
            text-align: left;
            font-size: 32px;
            height: 32px;
        }

        .name {
            @extend .font7;
            color: $color-gray-mid;
            
            &:hover {
                cursor: pointer;
                text-decoration: underline;
            }
        }

        .date {
            @extend .font5;
            text-align: left;
            color: $color-gray;
        }
        .option{
            display: flex;
            flex-direction: column;
            width: 100%;
            justify-content: end;
            align-items: end;


            :hover {
                cursor: pointer;
            }

            #optionIcon {
                width: 24px;
                height: 24px;
                color: $color-gray;
            }
        }
        .dropDown-list {
            position: absolute;
            top: 100%;
            right: 0;
            transform: translateY(-15px);
            width: fit-content;
            display: flex;
            padding: 3px;
            flex-direction: column;
            align-items: flex-end;
            gap: 10px;
            border-radius: 10px;
            opacity: 0.95;
            background: #FFF;
            box-shadow: 0px 0px 10px 0px rgba(170, 170, 170, 0.50);
            z-index: 1;

            .dropDown-item {
                display: flex;
                width: 100%;
                padding: 5px 10px;
                justify-content: center;
                align-items: center;
                gap: 3px;

                &:hover {
                    background-color: #F0F6FF;
                    color: #287EFF;
                    cursor: pointer;
                }

                span {
                    text-align: center;
                    font-family: Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 12px;
                    /* 100% */
                    letter-spacing: 0.5px;
                }
            }
        }    
        
    }
}

.stepProgressBar {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-gray;
    @extend .font6;
    gap: 16px;
    min-width: 1080px;

    .stepUnit {
        display: flex;
        text-align: center;
        align-items: center;
        gap: 16px;
        border: 1px solid rgba(203, 213, 225, 1);
        border-radius: 45px;
        padding: 4px 24px;


        .bullet {
            display: flex;
            align-items: center;

            .circleDone {
                display: none;
            }

        }

        .text {
            white-space: pre;
        }

        //进行中
        &.ing {
            border-color: $color-blue-mid;
            color: $color-blue-mid;
            background-color: $color-blue-light;
        }

        //已完成
        &.done {
            .circle {
                display: none;
            }

            .circleDone {
                display: inline-block;
                color: $color-blue-mid;
            }
        }
    }

    .arrowR {
        @extend .font3;
    }

}

.bgBar {
    &-blue {
        background-color: rgba(240, 246, 255, 1);
        padding: 20px 28px;
        border-radius: 12px;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
        display: flex;
        align-items: center;
    }
    .content{
        .title{
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }
}

.progressBar {}

.inputBox {
    &-text {}

    &-select {}

    &-paragragh {}

    &-slider {}
}



.starTag {
    &::after {
        content: "*";
        color: $color-red;
    }
}

.QAInputGroup {
    @extend .baseUnit;

    .QAInputUnit {
        @extend .baseGap;

        .A {
            width: 72%;
            position: relative;



            .iconR {
                color: $color-blue-dark;
                position: absolute;
                font-size: 28px;
                top: 8px;
                right: 4px;
            }
        }

    }

}

.quickStart {

    .boxCard-function {
        width: 280px;
        height: 260px;

    }

}

.prevFiles {
    .cardGroup {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        height: auto;

        .fileCard {
            min-width: 250px;
            min-width: 250px;
            min-height: 160px;

        }

    }
}

.tipCloud {
    display: flex;
    gap: 20px;

    .tipUnit {
        padding: 10px 20px;
        border: 2px solid $color-gray;
        border-radius: 10px;
        box-shadow: 0 4px 10px 0px $color-gray;
        cursor: pointer;
        transition: .3s;

        &:hover {
            border-color: $color-blue-mid;
            color: $color-blue-mid;
            scale: 1.2;
        }
    }
}