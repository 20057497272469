$fontWeight-semibold:600;
$fontWeight-regular:400;
$fontWeight-bold:700;

.font1 {
    font-family: Poppins;
    font-size: 72px;
    font-weight: $fontWeight-semibold;
    line-height: 90px;
    text-align: left;

    @media screen and (max-width:$breakpoint-mid) {
        & {
            font-size: 56px;
            line-height: 76px;
            text-wrap: wrap;
            word-wrap: break-word;
            // word-break: break-all;
        }
    }

    @media screen and (max-width:$breakpoint-small) {
        & {
            font-size: 56px;
            line-height: 76px;
            text-wrap: wrap;
            word-wrap: break-word;
            word-break: break-all;
        }
    }
}

.font2 {
    font-family: Poppins;
    font-size: 36px;
    font-weight: $fontWeight-semibold;
    line-height: 34px;
    text-align: left;

    @media screen and (max-width:$breakpoint-small) {
        & {
            font-size: 36px;
            line-height: 34px;
            text-wrap: wrap;
            word-wrap: break-word;
            word-break: break-all;
        }
    }

}

.font3 {
    font-family: Poppins;
    font-size: 24px;
    font-weight: $fontWeight-semibold;
    line-height: 28px;
    text-align: left;


}

.font4 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: $fontWeight-regular;
    line-height: 28px;
    text-align: left;


}

.font5 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: $fontWeight-regular;
    line-height: 20px;
}

.font6 {
    font-size: 20px;
    font-weight: $fontWeight-bold;

}
.font7 {
    font-size: 16px;
    font-weight: $fontWeight-bold;

}
.font8 {
    font-size: 12px;
    font-weight: $fontWeight-bold;

}


.fontbutton {}

.font-text {
    font-size: 20px;
    font-weight: 600;
}

.font-size-icon {
    font-size: 36px;
}