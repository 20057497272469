.payment-page {
    background: var(--Home-site-Basic-0, #FAFAFA);
    width: 100vw;
    height: calc(100vh - 80px);
    overflow: hidden;
    position: relative;

    &_header {
        height: 95px;
        border-bottom: 1px #EAEAEA solid;
        padding-top: 35px;
        padding-left: 160px;
    }

    &_content {
        width: 100vw;
        height: calc(100vh - 100px);
        display: flex;
        justify-content: center;
        align-items: center;
        display: flex;
        justify-content: space-between;
    }

    &_left {
        width: 55vw;
        padding: 60px 50px 60px 90px;
    }

    &_right {
        width: 45vw;
        background-color: #F4F7F8;
        padding: 60px 50px;
    }

    &_title {
        color: #000;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 40px;
    }

    &_exit {
        position: absolute;
        top: 75px;
        left: 35px;
        background-color: transparent;
        border: none;
    }


}



.payment-options {
    height: calc(100vh - 350px);
    min-height: 500px;
    position: relative;
    padding: 0 10px;


    &_form {
        width: 100%;
        height: 440px;
        overflow: hidden;
    }

    &_card {
        &_header {
            background-color: #fff;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 12px 12px 0 0;
            height: 74px;
            padding: 26px 29px;


            &_title {
                color: #000;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            &_img {
                height: 24px;
                display: flex;
                gap: 10px;

                img {
                    height: 100%;
                }
            }
        }

        &_content {
            padding: 20px 21px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            border: 1px solid #DEDEDE;
            background: #F4F4F4;
            border-left: 1px solid var(--Home-site-Basic-2, #AAA);
            border-right: 1px solid var(--Home-site-Basic-2, #AAA);

            * {
                border-radius: 5px;
                height: 47px;
                border: 1px solid var(--Home-site-Main1, #DEDEDE);
                background: #FFF;
                padding: 0 16px;

                &:focus {
                    border: 1px solid var(--Home-site-Main1, #287EFF);
                }
            }

            select {
                option:first-child {
                    width: calc(100% - 32px);
                    color: #AAA;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                // appearance: none;
            }

            input::placeholder {
                color: #AAA;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }

    &_btnPay {
        display: flex;
        width: calc(100% - 20px);
        padding: 13px 186px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 12px;
        background: #287EFF;
        color: #FFF;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
        position: absolute;
        bottom: 0
    }

    &_qrcode {
        background: #F4F4F4;
        height: calc(100% - 148px);
        padding: 35px 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 26px;
        border-left: 1px solid var(--Home-site-Basic-2, #AAA);
        border-right: 1px solid var(--Home-site-Basic-2, #AAA);
        border-bottom: 1px solid var(--Home-site-Basic-2, #AAA);

        img {
            height: 100%;
        }

        &_info {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}

.invalid {
    border: 1px solid red;
}

.payment_popup {
    position: absolute;
    top: 0;
    height: 100vh;
    width: 100vw;


    &_container {
        top: 100vh;
        left: 110px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;

    }

    &_content {
        // position: relative;
        width: 440px;
        height: 310px;
        border-radius: 16px;
        background-color: #FAFAFA;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 40px;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
        padding: 80px 70px 70px;

        &_title {
            font-size: 20px;
            font-weight: 600;
            text-align: center;
        }


        &_btns {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

        }
    }

    .close-button {
        position: absolute;
        top: 30px;
        right: 30px;
        width: 16px;
    }

    .back-button {
        color: #AAA;
        font-size: 16px;
        border: transparent;
        background-color: transparent;
    }

    .submit-button {
        width: 100px;
        height: 40px;
        background-color: #287EFF;
        border-radius: 8px;
        color: #F4F7F8;
        border: transparent;

    }
}


.payment_result_popup {
    position: absolute;
    top: 0;
    height: 100vh;
    width: 100vw;
    z-index: 9999;


    &_container {
        top: 100vh;
        left: 110px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;

    }

    &_content {
        // position: relative;
        width: 540px;
        // height: 380px;
        border-radius: 16px;
        background-color: #FAFAFA;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 40px;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
        padding: 80px 70px 70px;

        &_title {
            font-size: 20px;
            font-weight: 600;
            text-align: center;
        }

        &_text {
            font-size: 16px;
            text-align: justify;
        }


        &_btns {
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 10px;
            justify-content: center;
            align-items: center;

            p {
                color: #AAAAAA;
            }
        }
    }

    &_submit-button {
        width: 230px;
        height: 40px;
        background-color: #287EFF;
        border-radius: 8px;
        color: #F4F7F8;
    }

    .close-button {
        position: absolute;
        top: 30px;
        right: 30px;
        width: 16px;
    }

    .back-button {
        color: #AAA;
        font-size: 16px;
    }

}


.check_order_popup {
    position: absolute;
    top: 0;
    height: 100vh;
    width: 100vw;


    &_container {
        top: 100vh;
        left: 110px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;

    }

    &_content {
        // position: relative;
        width: 640px;
        height: 410px;
        border-radius: 16px;
        background-color: #FAFAFA;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
        padding: 60px 70px 50px;

        &_title {
            font-size: 20px;
            font-weight: 600;
            text-align: center;

            &:nth-child(2) {
                margin-bottom: 20px;
            }
        }

        &_text {
            font-size: 14px;
            font-weight: 400;
            text-align: center;
        }


        &_btns {
            margin-top: 20px;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
        }
    }

    .close-button {
        position: absolute;
        top: 30px;
        right: 30px;
        width: 16px;
    }

    .back-button {
        color: #AAA;
        font-size: 16px;
    }

    .submit-button {
        width: 100px;
        height: 40px;
        background-color: #287EFF;
        border-radius: 8px;
        color: #F4F7F8;

    }

}


.plan-details {
    height: calc(100vh - 350px);
    min-height: 500px;
    position: relative;

    &_choices {
        display: flex;
        justify-content: space-between;
        margin-bottom: 60px;
        gap: 20px;
    }

    &_choice {
        width: 100%;
        height: 136px;
        padding: 26px;
        border-radius: 5px;
        cursor: pointer;
        border-radius: 20px;
        border: 1px solid var(--Home-site-Basic-2, #AAA);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        text-align: start;

        &_title {
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            display: flex;
            flex-wrap: wrap;
            gap: 10px;

            span {
                display: inline-flex;
                padding: 2px 6px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                border-radius: 6px;
                background: var(--Main1, #287EFF);
                color: #FFF;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }

        &_price {
            color: #000;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
            text-align: start;

            span {
                color: #000;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }


        }
    }

    &_choice_selected {
        width: 100%;
        height: 136px;
        padding: 26px;
        border-radius: 5px;
        cursor: pointer;
        border-radius: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        text-align: start;
        border-radius: 20px;
        border: 1px solid var(--Home-site-Main1, #287EFF);
        background: var(--Home-site-Secondary-1, #EEF5FF);
    }

    &_intro {
        width: 100%;
        color: rgba(0, 0, 0, 0.80);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 45px;
        padding: 0 20px;
    }

    &_intros {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 10px;
        padding: 0 20px;

        &_list {
            display: flex;
            font-weight: 600;

            svg {
                margin-right: 10px;
                width: 22px;
                height: 22px;
            }

            // &:nth-child(1),
            // &:nth-child(2) {
            //     font-weight: 600;
            // }
        }
    }

    &_price {
        position: absolute;
        right: 10px;
        bottom: 0;
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        display: flex;
        align-items: center;
        gap: 30px;

        span {
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: capitalize;
            margin-left: 5px;
            margin-right: 5px;
        }

        //submit button
        .submit-button {
            width: 100px;
            height: 40px;
            background-color: #287EFF;
            border: transparent;
            border-radius: 8px;
            color: #F4F7F8;
        }
    }
}