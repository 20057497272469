//排版
.in-block {
    display: inline-block;
}

.text-center {
    text-align: center;
}

.d-x {
    display: flex;
    flex-direction: row;
}

.d-y {
    display: flex;
    flex-direction: column;

}

//水平垂直布列
.d-x-center {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.d-y-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
}

.d-x-between {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}


.d-all-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

//水平垂直填充
.x-full {

    width: 100%;
}

.x-full-100p {
    width: 100%;
}


.y-full {
    height: 100%;
}

.y-screen {
    height: 100vh;
}

.all-full {
    width: 100%;
    height: 100%;
}

//常用边距
.pad-y-10 {
    padding-top: 10px;
    padding-bottom: 10px;
}

//响应式
.max-width {
    max-width: $size-max;
}

.R-show {
    display: none;
}

.R-show:hover {
    cursor: pointer;
}

.testshow {
    border: 1px solid black;
    background: gray;
}

@media screen and (max-width:$breakpoint-mid) {
    .R-hidden-mid {
        display: none;
    }
}

@media screen and (max-width:$breakpoint-small) {
    .R-hidden {
        display: none;
    }

    .R-show {
        display: block;
    }
}


//换行
.nowrap {
    flex-wrap: nowrap;
    white-space: nowrap;

}

.wrap {
    flex-wrap: wrap;
    white-space: wrap;
}

.overflow-hidden {
    overflow: hidden;

}