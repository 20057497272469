.programDetail-card {
    position: relative;
    width: 100%;
    height: auto;
    padding: 20px 20px;
    border: 1px solid #CCCCCC;
    border-radius: 16px;
    background-color: #FAFAFA;
    font-family: 'poppins';

    @media screen and (max-width: $breakpoint-small) {
        padding: 20px 10px;
    }


    .program-header {
        display: flex;
        align-items: start;
        justify-content: space-between;

        .logo {
            width: 43px;
            height: 43px;
            margin-right: 20px;
            border-radius: 10px;

            @media screen and (max-width: $breakpoint-small) {
                display: none;
            }
        }

        .school-info {
            flex-grow: 1;

            .course-name {
                font-size: 20px;
                font-weight: bold;
                margin: 0;

                @media screen and (max-width: $breakpoint-small) {
                    font-size: 14px;
                }
            }

            .school-name {
                font-size: 16px;
                color: #7c7c7c;
                font-weight: 600;

                @media screen and (max-width: $breakpoint-small) {
                    font-size: 12px;
                }
            }
        }

        .wishlistbookmark-container {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px 5px;
            border: 1px solid $color-gray;
            border-radius: 12px;
            font-size: 32px;

            &:hover {
                cursor: pointer;
                background-color: $color-blue-light;
            }

            @media screen and (max-width: $breakpoint-small) {
                font-size: 24px;
            }

            #wishlist-icon {
                color: $color-blue-mid;
            }
        }

        .wishlistClose-container {
            display: flex;
            justify-content: end;
            align-items: start;
            font-size: 24px;

            &:hover {
                cursor: pointer;
                color: $color-blue-mid;
            }

            @media screen and (max-width: $breakpoint-small) {
                font-size: 24px;
            }
        }

        .PickCircle {
            width: 30px;
            height: 30px;
            background-color: #ccc;
            /* Default background color when not picked */
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            transition: background-color 0.3s ease;
            /* Smooth transition for background color */

            &.picked {
                background-color: #007bff;
                /* Blue background when picked */
            }

            .iconify {
                color: white;
                /* Set the color of the icon to white */
            }
        }


    }

    .tags {
        display: flex;
        gap: 10px;
        margin-bottom: 10px;

        @media screen and (max-width: $breakpoint-small) {
            flex-wrap: wrap;
            gap: 5px;
        }

        .tag {
            background-color: #e0e0e0;
            padding: 5px 10px;
            border-radius: 12px;
            font-size: 13px;
            color: #555;
            width: fit-content;

            @media screen and (max-width: $breakpoint-small) {
                padding: 5px 5px;
                font-size: 10px;
                font-weight: 600;
            }
        }

        .programLink {
            font-size: 13px;
            font-weight: bold;
            padding: 5px 10px;
            color: #555;

            @media screen and (max-width: $breakpoint-small) {
                padding: 5px 5px;
                font-size: 10px;
                font-weight: 600;
            }
        }
    }

    .expand-toggle {
        background: none;
        border: none;
        color: #007bff;
        cursor: pointer;
        font-size: 14px;


        &:hover {
            text-decoration: underline;
        }
    }

    .overview,
    .details,
    .admission {
        margin-top: 10px;

        h2 {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 10px;
            border-bottom: 1px solid #CCCCCC;

            @media screen and (max-width: $breakpoint-small) {
                font-size: 14px;
            }
        }

        p {
            font-size: 14px;
            line-height: 1.5;
            color: #333;

            @media screen and (max-width: $breakpoint-small) {
                font-size: 12px;
            }
        }

        ul,
        ol {
            padding-left: 20px;
            margin: 0;
            font-size: 14px;

            li {
                margin-bottom: 8px;
                color: #555;

                @media screen and (max-width: $breakpoint-small) {
                    font-size: 12px;
                    margin-bottom: 4px;
                }
            }
        }

        ul {
            list-style-type: disc;
        }

        ol {
            list-style-type: decimal;
        }
    }

    .details {
        display: flex;
        gap: 20px;

        @media screen and (max-width: $breakpoint-small) {
            flex-direction: column;
        }

        section {
            flex: 1;
        }
    }
}

.programFilter {
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: start;
    font-family: 'Poppins';
    width: 30%;
    max-height: calc(100vh - 190px);
    overflow-y: auto;
    scrollbar-width: thin;

    @media screen and (max-width: $breakpoint-small) {
        width: 100%;
    }

    &-searchSection {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
        padding: 16px 16px;
        border: 1px solid $color-mid;
        border-radius: 16px;

        @media screen and (max-width: $breakpoint-small) {
            padding: 8px;
            gap: 4px;
        }

        label {
            color: #1F384C;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 23px;
            /* 127.778% */
            letter-spacing: 0.5px;

            @media screen and (max-width: $breakpoint-small) {
                font-size: 12px;
            }
        }

        .searchComponent {
            display: flex;
            flex-direction: column;
            width: 100%;
            gap: 10px;

            .searchInput {
                width: 100%;
                font-size: 12px;
                word-break: break-all;
                border-radius: 8px;

            }

            .searchTextInput {
                border: 1px rgb(200, 203, 217) solid;
                border-radius: 4px;
                font-size: 12px;
                height: 36px;
                padding: 10px;
            }

            .filterSubmit {
                color: #FFF;
                font-family: Poppins;
                font-size: 18px;
                font-weight: 500;
                line-height: 23px;
                letter-spacing: 0.5px;
                display: flex;
                padding: 9px 0px;
                justify-content: center;
                align-items: center;
                border-radius: 8px;
                border: 2px solid transparent;
                background: var(--Main1, #287EFF);

                &:hover {
                    border: 2px solid black;
                }
            }
        }


    }

    &-filterSection {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
        padding: 16px 16px;
        border: 1px solid $color-gray-mid;
        border-radius: 16px;
        font-family: 'Poppins';

        .filterTitle {
            display: flex;
            justify-content: space-between;
            align-items: center;

            label {
                color: #1F384C;
                font-family: Poppins;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 23px;
                /* 127.778% */
                letter-spacing: 0.5px;

                @media screen and (max-width: $breakpoint-small) {
                    font-size: 12px;
                }
            }

            .ClearAllContainer {
                display: flex;
                align-items: center;
                gap: 6px;
                width: fit-content;

                .ClearAllSpan {
                    color: var(--Icon_1, #AAA);
                    font-family: Poppins;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                    /* 183.333% */
                    letter-spacing: 0.5px;

                }

                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }

                #clearAllIcon {
                    width: 20px;
                    height: 20px;
                    color: var(--Icon_1, #AAA)
                }
            }
        }

        .filterBody {
            .MajorComponent {
                display: flex;
                flex-direction: column;
                gap: 12px;
                padding: 12px 0;
                border-top: 1px solid var(--Border-1, #C8CBD9);
                transition: all 0.3s ease;


                &-title {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    font-size: 16px;
                    color: #7C7C7C;

                    span {
                        font-family: Poppins;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 22px;
                        /* 137.5% */
                        letter-spacing: 0.5px;
                    }

                    &:hover {
                        text-decoration: underline;
                        cursor: pointer;
                    }

                    .countingBlock {
                        display: flex;
                        width: 16px;
                        height: 16px;
                        padding: 2px 2px;
                        justify-content: center;
                        align-items: center;
                        border-radius: 4px;
                        background: #AAA;
                        color: #FFF;
                        font-family: Poppins;
                        font-size: 12px;
                    }
                }

                &-body {
                    display: flex;
                    flex-direction: column;
                    gap: 12px;

                    max-height: 200px;
                    overflow-y: scroll;
                    scrollbar-width: none;

                    .majorTypelabel {
                        font-size: 16px;
                        text-decoration: underline;
                    }

                    .majorOption {
                        display: flex;
                        gap: 4px;
                        align-items: center;

                        label {
                            font-size: 14px;
                            word-break: break-all;
                            display: flex;
                            gap: 4px;

                            &:hover {
                                text-decoration: underline;
                                cursor: pointer;
                            }
                        }
                    }
                }


            }

            .SliderComponent {
                display: flex;
                flex-direction: column;
                gap: 12px;
                padding: 12px 0;
                border-top: 1px solid var(--Border-1, #C8CBD9);
                transition: all 0.3s ease;
                color: #7C7C7C;


                &-title {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    font-size: 16px;
                    color: #7C7C7C;

                    span {
                        font-family: Poppins;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 22px;
                        /* 137.5% */
                        letter-spacing: 0.5px;
                    }

                    &:hover {
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }

                &-body {

                    padding: 10px 5px;

                    .slider-container {
                        display: flex;
                        flex-direction: column;
                        width: 100%;

                        .track {
                            height: 6px;
                            border-radius: 3px;
                            background: #ccc;
                            margin: 20px 0;
                            position: relative;
                        }

                        .program-thumb {
                            height: 20px;
                            width: 20px;
                            border-radius: 50%;
                            background-color: $color-blue-mid;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            position: relative;

                            &-label {
                                position: absolute;
                                top: -25px;
                                /* Adjust this to fit the label better */
                                color: #000;
                                font-weight: bold;
                                font-size: 12px;
                                white-space: nowrap;
                            }
                        }
                    }
                }
            }
        }

        .filterSubmit {
            color: #FFF;
            font-family: Poppins;
            font-size: 18px;
            font-weight: 500;
            line-height: 23px;
            letter-spacing: 0.5px;
            display: flex;
            padding: 9px 0px;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            border: 2px solid transparent;
            background: var(--Main1, #287EFF);

            &:hover {
                border: 2px solid black;
            }
        }
    }
}

.programResourcePage {
    display: flex;
    flex-direction: column;
    word-break: break-all;

    @media screen and (max-width: $breakpoint-small) {
        padding: 8px;

    }

    .programResourceHead {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 26px;
        width: 100%;

        @media screen and (max-width: $breakpoint-small) {
            flex-direction: column;
            gap: 10px;
        }

        .programResourceLabel {
            font-family: 'poppins';
            font-size: 18px;
            font-weight: 500;
            line-height: 23px;
            letter-spacing: .5px;

            @media screen and (max-width: $breakpoint-small) {
                font-size: 16px;
            }

        }

        .programResourceWishlist {
            display: flex;
            gap: 9px;
            align-items: center;
            justify-content: start;

            label {
                width: fit-content;
                color: #7C7C7C;
                font-family: "Poppins";
                font-size: 16px;
                font-weight: 500;
                letter-spacing: 0.5px;

                &:hover {
                    text-decoration: underline;
                    cursor: pointer;
                }

                @media screen and (max-width: $breakpoint-small) {
                    font-size: 12px;
                }
            }

            .bookmarkIcon {
                height: 16px;
                width: 16px;
                color: $color-blue-mid;

                @media screen and (max-width: $breakpoint-small) {
                    display: none;
                }
            }
        }


    }

    .programResourceBody {
        display: flex;
        flex-direction: row;
        gap: 30px;

        @media screen and (max-width: $breakpoint-small) {
            flex-direction: column;
        }



        .programResourceCardContainer {
            display: flex;
            width: 100%;
            flex-direction: column;
            gap: 20px;
            max-height: calc(100vh - 190px);
            overflow-y: scroll;


            .pagination {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 20px;
                font-size: 16px;

                @media screen and (max-width: $breakpoint-small) {
                    font-size: 12px;
                }

                span {
                    margin: 0 5px;
                    padding: 5px 10px;
                    cursor: pointer;
                    color: #1a73e8; // Blue color similar to Google's

                    @media screen and (max-width: $breakpoint-small) {
                        padding: 5px 3px;
                        margin: 0 2px;
                    }

                    &.active {
                        font-weight: bold;
                        text-decoration: underline;
                    }

                    &:hover {
                        text-decoration: underline;
                    }

                    &.next,
                    &.prev {
                        font-size: 18px;
                        color: #1a73e8;
                    }

                    &.ellipsis {
                        cursor: default;
                        text-decoration: none;
                    }
                }
            }

        }
    }
}

.programWishlistPage {
    display: flex;
    flex-direction: column;


    &-Head {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 20px;

        .programWishlistLabel {
            font-family: 'poppins';
            font-size: 18px;
            font-weight: 500;
            line-height: 23px;
            letter-spacing: .5px;
        }

        span {
            width: fit-content;
            color: #7C7C7C;
            font-family: "Poppins";
            font-size: 16px;
            font-weight: 500;
            letter-spacing: 0.5px;

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }

            @media screen and (max-width: $breakpoint-small) {
                font-size: 12px;
            }
        }
    }

    &-control {
        display: flex;
        gap: 10px;
        align-items: center;
        margin-bottom: 20px;
        font-family: 'Poppins';
        font-size: 14px;
        color: $color-gray-mid;

        .sortSelect {
            border: transparent;
            color: $color-gray-mid;
        }
    }

    &-Body {
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-height: calc(100vh - 200px);
        overflow-y: scroll;
    }
}